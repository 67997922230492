// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
// import { mobileCheck } from "./functions/mobile-check";
// console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/disable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';
// const rules1 = [...];

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');
// };

// validateForms('.form-1', rules1, afterForm);

import {isMobile, isTablet, isDesktop} from './functions/check-viewport';


document.querySelectorAll(".inputDecor input, .inputDecor textarea").forEach((element) => {
    element.oninput = function () {
        if (this.value.trim().length === 0) {
            this.classList.remove("act")
        } else {
            this.classList.add("act");
        }
    };
    if (element.value.trim().length === 0) {
        element.classList.remove("act")
    } else {
        element.classList.add("act");
    }
});

document.querySelectorAll("[data-popular-btn]").forEach((element) => {
    element.onclick = function () {
        if (this.classList.contains("popular__tab--active")) {
        } else {
            document.querySelectorAll("[data-popular-btn]").forEach((element) => {
                element.classList.remove("popular__tab--active")
            })
            document.querySelectorAll("[data-popular-block]").forEach((element) => {
                element.classList.remove("popular__brands--active")
            })
            this.classList.add("popular__tab--active")
            document.querySelector(`[data-popular-block="${this.dataset.popularBtn}"]`).classList.add("popular__brands--active")
        }
    };
});

let scrollPos = 0;
let heightHidden = document.querySelector(".headerTop").scrollHeight + document.querySelector(".headerBottom").scrollHeight + "px"
window.addEventListener('scroll', function () {
    scrollPos = document.documentElement.scrollTop
    if (scrollPos != 0) {
        this.document.querySelector(".main").style.marginTop = heightHidden
        this.document.querySelector(".header").classList.add("header--scroll")
        this.document.querySelector(".headerTop").classList.add("headerTop--scroll")
        this.document.querySelector(".headerMiddle").classList.add("headerMiddle--scroll")
        this.document.querySelector(".headerBottom").classList.add("headerBottom--scroll")
        this.document.querySelector(".headerMob").classList.add("headerMob--scroll")
        this.document.querySelector(".headerKatalog").classList.add("headerKatalog--scroll")
    } else {
        this.document.querySelector(".header").classList.remove("header--scroll")
        this.document.querySelector(".headerTop").classList.remove("headerTop--scroll")
        this.document.querySelector(".headerMiddle").classList.remove("headerMiddle--scroll")
        this.document.querySelector(".headerBottom").classList.remove("headerBottom--scroll")
        this.document.querySelector(".headerMob").classList.remove("headerMob--scroll")
        this.document.querySelector(".headerKatalog").classList.remove("headerKatalog--scroll")
        this.document.querySelector(".main").style.marginTop = '0px'
    }

    if (scrollPos >= 350) {
        $(".productHead").addClass("productHead--active")
    } else {
        $(".productHead").removeClass("productHead--active")
    }

    if (document.querySelector(".comparison__sliderWrap")) {
        if (document.querySelector(".comparison__sliderWrap").getBoundingClientRect().top <= $(".header").height()) {
            $(".comparison__sliderSticky").addClass("comparison__sliderSticky--active")
            $(".comparison__sliderSticky").offset({top: $(".header").height() + scrollPos});
        } else {
            $(".comparison__sliderSticky").removeClass("comparison__sliderSticky--active")
            $(".comparison__sliderSticky").offset({top: 0});
        }
    }


    if (isMobile()) {
        $(".clue__clue--active").removeClass("clue__clue--active")
    }


    // $(".clue__clue--active").removeClass("clue__clue--active")
    // this.setTimeout(() => {
    //    $(".clue__clue").removeClass("clue__clue--bottom")
    // }, 400)

});


document.querySelector(".comparisonModal__contentWrap").addEventListener('scroll', function () {
    if (document.querySelector(".comparisonModal .sliderNavWrap").getBoundingClientRect().top <= $(".header").height() * -1) {
        $(".comparison__sliderSticky").addClass("comparison__sliderSticky--active")
        $(".comparison__sliderSticky").offset({top: 0});
    } else {
        $(".comparison__sliderSticky").removeClass("comparison__sliderSticky--active")
        $(".comparison__sliderSticky").offset({top: 0});
    }
})

document.querySelector(".headerMiddle__searchBg").onclick = function () {
    document.querySelector(".headerMiddle__searchBg").classList.remove("headerMiddle__searchBg--active")
    document.querySelector(".searchResult").classList.remove("searchResult--active")
    document.body.style.paddingRight = "0px"
    document.body.style.overflow = 'initial';
};

let zIndex = 1

function openModal(modalName) {
    $(".productBottom").addClass("productBottom--hidden")
    $(".productHead").addClass("productHead--hidden")
    if (isNaN(parseInt(document.body.style.paddingRight)) || parseInt(document.body.style.paddingRight) == 0) {
        document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + "px"
    }
    document.body.style.overflow = 'hidden';
    if (document.querySelector("[data-graph-target='" + modalName + "']").classList.contains("graph-modal-open-ok")) {
        document.querySelector(".graph-modal-open").classList.add("close")
        document.querySelector(".graph-modal-open-ok").classList.remove("graph-modal-open-ok")
        setTimeout(() => {
            document.querySelector(".graph-modal__container.close").classList.remove("close")
        }, 400);
        document.querySelector(".graph-modal-open").classList.remove("graph-modal-open")
        document.querySelector(".graph-modal").classList.remove("is-open")
        return
    } else if (document.querySelector(".graph-modal-open-ok.delay")) {
        document.querySelector(".graph-modal-open-ok").classList.add("time")
        setTimeout(() => {
            document.querySelector(".graph-modal-open.time").classList.add("close")
            document.querySelector(".graph-modal-open-ok.time").classList.remove("graph-modal-open-ok")
            setTimeout(() => {
                document.querySelector(".graph-modal__container.close").classList.remove("close")
                document.querySelector(".graph-modal__container.time").classList.remove("time")
            }, 400);
            document.querySelector(".graph-modal-open.time").classList.remove("graph-modal-open")
        }, 400);
    } else if (document.querySelector(".graph-modal-open")) {
        document.querySelector(".graph-modal-open").classList.remove("graph-modal-open")
        document.querySelector(".graph-modal-open-ok").classList.remove("graph-modal-open-ok")
    }
    document.querySelector(".graph-modal").classList.add("is-open")
    document.querySelector("[data-graph-target='" + modalName + "']").classList.add("graph-modal-open")
    document.querySelector("[data-graph-target='" + modalName + "']").style.zIndex = zIndex
    zIndex++
    setTimeout(() => {
        document.querySelector("[data-graph-target='" + modalName + "']").classList.add("graph-modal-open-ok")
    }, 5);
}

window.closeModal = function() {
    $(".productBottom").removeClass("productBottom--hidden")
    $(".productHead ").removeClass("productHead--hidden")
    document.body.style.paddingRight = 0
    document.body.style.overflow = 'auto';
    document.querySelector(".graph-modal").classList.remove("is-open")
    if (document.querySelector(".graph-modal-open")) {
        document.querySelector(".graph-modal-open").classList.add("close")
        document.querySelector(".graph-modal-open-ok").classList.remove("graph-modal-open-ok")
        setTimeout(() => {
            document.querySelector(".graph-modal__container.close").classList.remove("close")
        }, 400);
        document.querySelector(".graph-modal-open").classList.remove("graph-modal-open")
    }
    document.querySelector(".graph-modal").style.zIndex = 1000;
}

window.initModalBtn = () => {
    if (document.querySelector(".modal-btn:not(.modal-btn-inited)")) {
        document.querySelectorAll(".modal-btn:not(.modal-btn-inited)").forEach((element) => {
            element.classList.add("modal-btn-inited");
            element.onclick = function (e) {
                if (this.dataset.mainmenuItem) {
                    App.initMobileMenuModal(this.dataset.mainmenuItem);
                }
                if (this.dataset.graphPath) {
                    openModal(this.dataset.graphPath)
                }
            }
        })
    }
};
window.initModalBtn();

// if (document.querySelector(".graph-modal__container")) {
//    document.querySelectorAll(".graph-modal__container").forEach((element) => {
//       element.onclick = function (e) {
//          e.stopPropagation()
//       }
//    })
// }
if (document.querySelector(".js-modal-close")) {
    document.querySelectorAll(".js-modal-close").forEach((element) => {
        element.onclick = function (e) {
            closeModal()
        }
    })
}
document.querySelector(".graph-modal").onclick = function (e) {
    if (e.target == this) {
        closeModal()
    }
}
document.addEventListener('keydown', function (e) {
    if (e.keyCode === 27) {
        closeModal()
    }
});

searchMob.addEventListener('input', function (e) {
    if (e.target.value == '') {
        document.querySelector('.modalSearch__contentWrap').classList.remove("modalSearch__contentWrap--active")
    } else {
        document.querySelector('.modalSearch__contentWrap').classList.add("modalSearch__contentWrap--active")
    }
});

document.querySelectorAll("[data-menu-item]").forEach((element) => {
    element.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelectorAll("[data-menu-block]").forEach((element) => {
            element.classList.remove("headerKatalog__content--active")
        })
        document.querySelector("[data-menu-block='" + element.dataset.menuItem + "']").classList.add("headerKatalog__content--active")
        document.querySelector(".headerKatalog__contentBack").classList.add("headerKatalog__content--active");
    });
});

document.querySelector("#headerKatalog__back").addEventListener('click', function (e) {
    this.parentElement.classList.remove("headerKatalog__content--active")
    document.querySelectorAll("[data-menu-block]").forEach((element) => {
        element.classList.remove("headerKatalog__content--active")
    })
    document.querySelector("[data-menu-block='main']").classList.add("headerKatalog__content--active")
});

document.querySelector(".headerMiddle__btnCatalog").addEventListener('click', function (e) {
    if (this.classList.contains("headerMiddle__btnCatalog--active")) {
        this.classList.remove("headerMiddle__btnCatalog--active")
        document.body.style.paddingRight = 0
        // document.body.style.overflow = 'auto';
        $(".page__body").removeClass('overflow')
        document.querySelector(".headerKatalog").classList.remove("headerKatalog--active")
    } else {
        this.classList.add("headerMiddle__btnCatalog--active")
        document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + "px"
        // document.body.style.overflow = 'hidden';
        $(".page__body").addClass('overflow')
        document.querySelector(".headerKatalog").classList.add("headerKatalog--active")
    }
})
document.querySelector(".headerKatalog__contenClose").addEventListener('click', function (e) {
    document.querySelector(".headerMiddle__btnCatalog").classList.remove("headerMiddle__btnCatalog--active")
    document.body.style.paddingRight = 0
    // document.body.style.overflow = 'auto';
    $(".page__body").removeClass('overflow')
    document.querySelector(".headerKatalog").classList.remove("headerKatalog--active")
    document.querySelectorAll("[data-menu-block]").forEach((element) => {
        element.classList.remove("headerKatalog__content--active")
    })
    document.querySelector("[data-menu-block='main']").classList.add("headerKatalog__content--active")
    document.querySelector(".headerKatalog__contentBack").classList.remove("headerKatalog__content--active");
})

document.querySelectorAll(".basketHover__remove").forEach((element) => {
    element.addEventListener('click', function (e) {
        this.closest('.basketHover__card').remove();
    });
});

window.initCatalogPageTabs__more = function () {
    if (document.querySelector(".catalogPageTabs__more")) {
        document.querySelectorAll(".catalogPageTabs__more:not(.event_init)").forEach((element) => {
            element.classList.add("event_init")
            element.addEventListener('click', function (e) {
                this.closest(".catalogPageTabs").classList.add("catalogPageTabs--active")
                this.remove()
            });
        })
    }
}
initCatalogPageTabs__more();

if (document.querySelector(".catalogPageFilter__testClose")) {
    document.querySelector(".catalogPageFilter__testClose").addEventListener('click', function (e) {
        this.closest(".catalogPageFilter__test").remove()
    });
}

if (document.querySelector(".filters__btn")) {
    document.querySelectorAll(".filters__btn").forEach((element) => {
        element.onclick = function (e) {
            this.classList.toggle("filters__btn--active")
            $(this).closest(".filters").find(".filters__content").slideToggle(400)
        }
    })
}

document.querySelectorAll(".filters__inpReset").forEach((element) => {
    element.addEventListener('click', function (e) {
        this.closest('.filters__inpLabel').querySelector("input").value = '';
        this.closest('.filters__inpLabel').querySelector("input").dispatchEvent(new Event("change"));
    });
});

document.querySelectorAll(".filters__moreBtn").forEach((element) => {
    element.addEventListener('click', function (e) {
        this.closest('.filters__content').querySelector(".filters").classList.add("filters--full")
        this.remove()
    });
});

let productCard__btn_z_index = 1
document.querySelectorAll(".productCard__btnFav").forEach((element) => {
    element.addEventListener('click', function (e) {
        this.classList.toggle("productCard__btn--active")
        if (this.classList.contains("productCard__btnFav") && this.classList.contains("productCard__btn--active")) {

            document.querySelector(".catalogFavoritePopup").classList.add("catalogFavoritePopup--active")
            if (document.querySelector(".productCard__addBasket")) {
                document.querySelector(".productCard__addBasket").classList.add("productCard__addBasket--active")
            }

            this.classList.add("productCard__btnFavRect")
            if (this.closest(".productCard")) {
                this.closest(".productCard").style.zIndex = productCard__btn_z_index
            }
            productCard__btn_z_index++

            setTimeout(() => {
                document.querySelector(".catalogFavoritePopup").classList.remove("catalogFavoritePopup--active")
                if (document.querySelector(".productCard__addBasket")) {
                    document.querySelector(".productCard__addBasket").classList.remove("productCard__addBasket--active")
                }
                this.classList.remove("productCard__btnFavRect")
            }, 5000)
        } else if (this.classList.contains("productCard__btnFav")) {
            document.querySelector(".catalogFavoritePopup").classList.remove("catalogFavoritePopup--active")
            if (document.querySelector(".productCard__addBasket")) {
                document.querySelector(".productCard__addBasket").classList.remove("productCard__addBasket--active")
            }
            this.classList.remove("productCard__btnFavRect")
        }
    });
});

window.initBtnLike = function() {
    document.querySelectorAll(".productCard__btnLike:not(.productCard__btnLike--init)").forEach((element) => {
        element.classList.add("productCard__btnLike--init");
        element.addEventListener('click', function (e) {
            this.classList.toggle("productCard__btn--active")
        });
    });
};
window.initBtnLike();

document.querySelectorAll(".catalogPageCatalogVariant__btn").forEach((element) => {
    element.addEventListener('click', function (e) {
        if (!this.classList.contains("catalogPageCatalogVariant__btn--active")) {
            document.querySelectorAll(".catalogPageCatalogVariant__btn").forEach((element) => {
                element.classList.remove("catalogPageCatalogVariant__btn--active")
            })
            this.classList.add("catalogPageCatalogVariant__btn--active")
            if (this.classList.contains("icon-view_list")) {
                document.querySelector(".productCards").classList.add("productCards--line")
            } else {
                document.querySelector(".productCards").classList.remove("productCards--line")
            }
        }
    });
});

if (document.querySelector(".catalogFavoritePopup__close")) {
    document.querySelector(".catalogFavoritePopup__close").addEventListener('click', function (e) {
        document.querySelector(".catalogFavoritePopup").classList.remove("catalogFavoritePopup--active")
    });
}

document.querySelectorAll(".productCard__addBasket").forEach((element) => {
    element.addEventListener('click', function (e) {
        e.stopPropagation()
    });
});

document.querySelectorAll(".productCard__addBasketClose").forEach((element) => {
    element.addEventListener('click', function (e) {
        this.closest(".productCard__addBasket").classList.remove("productCard__addBasket--active")
    });
});

document.querySelectorAll(".brends__lettersLink").forEach((element) => {
    element.addEventListener('click', function (e) {
        document.querySelectorAll(".brends__lettersLink").forEach((element) => {
            element.classList.remove("brends__lettersLink--active")
        })
        this.classList.add("brends__lettersLink--active")
    });
});

document.querySelectorAll(".tabs__tab").forEach((element) => {
    element.addEventListener('click', function (e) {
        document.querySelectorAll(".tabs__tab").forEach((element) => {
            element.classList.remove("tabs__tab--active")
        })
        this.classList.add("tabs__tab--active")
    });
});

document.querySelectorAll(".blog__tab").forEach((element) => {
    element.addEventListener('click', function (e) {
        document.querySelectorAll(".blog__tab").forEach((element) => {
            element.classList.remove("blog__tab--active")
        })
        this.classList.add("blog__tab--active")
    });
});

document.querySelectorAll(".faq__btn").forEach((element) => {
    element.addEventListener('click', function (e) {
        if (this.classList.contains("faq__btn--active")) {
            document.querySelectorAll(".faq__btn").forEach((element) => {
                element.classList.remove("faq__btn--active")
                $(element).closest(".faq__card").find(".faq__content").slideUp(400)
            })
        } else {
            document.querySelectorAll(".faq__btn").forEach((element) => {
                element.classList.remove("faq__btn--active")
                $(element).closest(".faq__card").find(".faq__content").slideUp(400)
            })

            this.classList.add("faq__btn--active")
            $(this).closest(".faq__card").find(".faq__content").slideDown(400)
        }
    });
});

window.initTabsBtn = function() {
    document.querySelectorAll("[data-tab]:not(.tabsBtn__tab--init)").forEach((element) => {
        element.onclick = function () {
            if (this.classList.contains("tabsBtn__tab--active")) {
            } else {
                document.querySelectorAll("[data-tab]").forEach((element) => {
                    element.classList.remove("tabsBtn__tab--active")
                })
                document.querySelectorAll("[data-tab-block]").forEach((element) => {
                    element.classList.remove("tabsContent__card--active")
                })
                this.classList.add("tabsBtn__tab--active")
                document.querySelector(`[data-tab-block="${this.dataset.tab}"]`).classList.add("tabsContent__card--active")
            }
        };
    });
}
window.initTabsBtn();


var dt = new DataTransfer();

$('.input-file input[type=file]').on('change', function () {
    let $files_list = $(this).closest('.input-file').next();
    $files_list.empty();
    if (this.files.length > 0) {
        $(this).closest(".input-file-row").find(".fileLabel").addClass("fileLabel--hidden")
    }

    for (var i = 0; i < this.files.length; i++) {
        let file = this.files.item(i);
        dt.items.add(file);

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            let new_file_input = '<div class="fileLabel__listItem">' +
                '<img src="' + reader.result + '">' +
                `<a href="#" class="fileLabel__listItemRemove">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="8" fill="#ED0000" /><path d="M11 5.60429L10.3957 5L8 7.39571L5.60429 5L5 5.60429L7.39571 8L5 10.3957L5.60429 11L8 8.60429L10.3957 11L11 10.3957L8.60429 8L11 5.60429Z" fill="white" /></svg>
            </a>` +
                '</div>';
            $files_list.append(new_file_input);
        }

    }
    this.files = dt.files;
});

function removeFilesItem(target) {
    let name = $(target).prev().text();
    let input = $(target).closest('.input-file-row').find('input[type=file]');
    $(target).closest('.fileLabel__listItem').remove();
    for (let i = 0; i < dt.items.length; i++) {
        if (name === dt.items[i].getAsFile().name) {
            dt.items.remove(i);
        }
    }
    input[0].files = dt.files;
}


$(document).on("click", ".fileLabel__listItemRemove", function (e) {
    e.preventDefault()
    if (this.closest(".input-file-row").querySelectorAll(".input-file-list.fileLabel__list > *").length <= 1) {
        $(this).closest(".input-file-row").find(".fileLabel").removeClass("fileLabel--hidden")
    }
    removeFilesItem(this)
});

var mapTitle = document.createElement('div');
mapTitle.className = 'mapTitle';
mapTitle.textContent = 'Для активации карты нажмите по ней';
if (document.getElementById("wrapMap")) {
    wrapMap.appendChild(mapTitle);
    wrapMap.onclick = function () {
        this.children[0].removeAttribute('style');
        mapTitle.parentElement.removeChild(mapTitle);
    }
    wrapMap.onmousemove = function (event) {
        mapTitle.style.display = 'block';
        if (event.offsetY > 10) mapTitle.style.top = event.offsetY + 20 + 'px';
        if (event.offsetX > 10) mapTitle.style.left = event.offsetX + 20 + 'px';
    }
    wrapMap.onmouseleave = function () {
        mapTitle.style.display = 'none';
    }
}


document.querySelectorAll("[data-tab-q]").forEach((element) => {
    element.onclick = function () {
        if (this.classList.contains("leftMenu__itemFirst--active")) {
        } else {
            document.querySelectorAll("[data-tab-q]").forEach((element) => {
                element.classList.remove("leftMenu__itemFirst--active")
            })
            document.querySelectorAll("[data-tab-block-q]").forEach((element) => {
                element.classList.remove("contactsBackCall__card--active")
            })
            this.classList.add("leftMenu__itemFirst--active")
            document.querySelector(`[data-tab-block-q="${this.dataset.tabQ}"]`).classList.add("contactsBackCall__card--active")
        }
    };
});

document.querySelectorAll(".brend__tab").forEach((element) => {
    element.addEventListener('click', function (e) {
        document.querySelectorAll(".brend__tab").forEach((element) => {
            element.classList.remove("brend__tab--active")
        })
        this.classList.add("brend__tab--active")
    });
});

if (document.querySelector("#fiz")) {
    document.querySelector("#fiz").addEventListener('click', function (e) {
        $(`[data-data="fiz"]`).addClass("basketForm__dataBlok--active")
        $(`[data-data="ur"]`).removeClass("basketForm__dataBlok--active")
    })
}
if (document.querySelector("#ur")) {
    document.querySelector("#ur").addEventListener('click', function (e) {
        $(`[data-data="ur"]`).addClass("basketForm__dataBlok--active")
        $(`[data-data="fiz"]`).removeClass("basketForm__dataBlok--active")
    })
}
if (document.querySelector("#delivery")) {
    document.querySelector("#delivery").addEventListener('click', function (e) {
        $(`[data-data="delivery"]`).addClass("basketForm__dataBlok--active")
        $(`[data-data="pickup"]`).removeClass("basketForm__dataBlok--active")
    })
}
if (document.querySelector("#pickup")) {
    document.querySelector("#pickup").addEventListener('click', function (e) {
        $(`[data-data="pickup"]`).addClass("basketForm__dataBlok--active")
        $(`[data-data="delivery"]`).removeClass("basketForm__dataBlok--active")
    })
}

$(".clue__info").on("click", function (e) {
    if ($(e.target).hasClass("clue__info")) {
        $(this).toggleClass("clue__info--active")
        $(this).closest(".clue").find(".clue__clue").toggleClass("clue__clue--active")
        $(this).closest(".product__creditTitleWrap").find(".clue__clue").toggleClass("clue__clue--active")
    }
})
$(".clue__clueClose").on("click", function (e) {
    $(this).closest(".clue").find(".clue__info").toggleClass("clue__info--active")
    $(this).closest(".clue").find(".clue__clue").toggleClass("clue__clue--active")
    $(this).closest(".product__creditTitleWrap").find(".clue__info").toggleClass("clue__info--active")
    $(this).closest(".product__creditTitleWrap").find(".clue__clue").toggleClass("clue__clue--active")
})

$(".basketProdCard__delete").on("click", function (e) {
    $(this).closest(".basketProdCard").remove()
})

if (document.querySelector(".quantity")) {
    document.querySelectorAll(".quantity__minus").forEach((element) => {
        element.onclick = function (e) {
            if (this.closest(".quantity").querySelector(".quantity__text span").textContent > 1) {
                this.closest(".quantity").querySelector(".quantity__text span").textContent -= 1
            }
        }
    })
    document.querySelectorAll(".quantity__plus").forEach((element) => {
        element.onclick = function (e) {
            this.closest(".quantity").querySelector(".quantity__text span").textContent = parseInt(this.closest(".quantity").querySelector(".quantity__text span").textContent) + 1
        }
    })
}

$(".labelEdit__btn").on("click", function (e) {
    $(this).closest(".labelEdit").find(".labelEdit__input").removeAttr("readonly")
    $(this).closest(".labelEdit").find(".labelEdit__input").focus()
})

$(".headerBannerText__close").on("click", function (e) {
    $(this).closest(".headerBannerText").addClass("headerBannerText--hidden")
    $(this).closest(".headerBannerDecor").addClass("headerBannerDecor--hidden")
})

$(".headerBannerDecor__closePc").on("click", function (e) {
    $(this).closest(".headerBannerDecor").addClass("headerBannerDecor--hidden")
})

$(".catalogCollectionCardProduct__btn").on("click", function (e) {
    $(this).closest(".catalogCollectionCardProduct__dateBtn").find(".catalogCollectionCardProduct__btn2").removeClass("catalogCollectionCardProduct__btn2--hidden")
    $(this).addClass("catalogCollectionCardProduct__btn--hidden")
    $(".catalogCollectionBanner").removeClass("catalogCollectionBanner--hidden")
    setTimeout(() => {
        $(".catalogCollectionBanner").addClass("catalogCollectionBanner--hidden")
    }, 5000)
})

$(".catalogCollectionCardProduct__btn2").on("click", function (e) {
    $(this).closest(".catalogCollectionCardProduct__dateBtn").find(".catalogCollectionCardProduct__btn").removeClass("catalogCollectionCardProduct__btn--hidden")
    $(this).addClass("catalogCollectionCardProduct__btn2--hidden")
    $(".catalogCollectionBanner").addClass("catalogCollectionBanner--hidden")
})

$(".product__codCopy").on("click", function (e) {
    var $tmp = $("<textarea>");
    $("body").append($tmp);
    $tmp.val($(this).closest(".product__cod").find("span").text()).select();
    document.execCommand("copy");
    $tmp.remove();
})

$("a.anchk").click(function () {
    $("html, body").animate({
        scrollTop: $($(this).attr("href")).offset().top - 100 + "px"
    }, {
        duration: 500,
        easing: "swing"
    });
    return false;
});

$(".product__topFav").on("click", function (e) {
    $(this).toggleClass("product__topBtn--active")
})

$(".product__topLike").on("click", function (e) {
    $(this).toggleClass("product__topBtn--active")
})

$(".product__infoBlockBtnSize").on("click", function (e) {
    $(this).closest(".product__infoBlockWrapSize").find(".product__infoBlockBtnSize").removeClass("product__infoBlockBtnSize--active")
    $(this).addClass("product__infoBlockBtnSize--active")
})

$(".product__creditPeriodBtn").on("click", function (e) {
    $(".product__creditPeriodBtn").removeClass("product__creditPeriodBtn--active")
    $(this).addClass("product__creditPeriodBtn--active")
})

$(".productNecessarily__btnMore").on("click", function (e) {
    $(this).closest(".productNecessarily__card").find(".productNecessarily__itemWrap").addClass("productNecessarily__itemWrap--active")
    $(this).addClass("productNecessarily__btnMore--active")
    $(".productNecessarily__btnMore--sv").addClass("productNecessarily__btnMore--sv--active")
})

$(".productNecessarily__btnMore--sv").on("click", function (e) {
    $(this).closest(".productNecessarily__card").find(".productNecessarily__itemWrap").removeClass("productNecessarily__itemWrap--active")
    $(this).removeClass("productNecessarily__btnMore--sv--active")
    $(".productNecessarily__btnMore").removeClass("productNecessarily__btnMore--active")
})

$(".reviews__cardContentGradeBtn").on("click", function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (!$(this).hasClass("reviews__cardContentGradeBtn--active")) {
        $(this).closest(".reviews__cardContentGrade").find(".reviews__cardContentGradeBtn").removeClass("reviews__cardContentGradeBtn--active")
        $(this).addClass("reviews__cardContentGradeBtn--active")
    } else {
        $(this).removeClass("reviews__cardContentGradeBtn--active")
    }
})

$(".comparisonMobBtns__remove").on("click", function (e) {
    $(this).closest(".comparisonMobBtns__btn").remove()
    e.stopPropagation()
})

$(".comparison__mobBlockBtn2").on("click", function (e) {
    $(this).removeClass("comparison__mobBlockBtn2--active")
    $(".comparison__mobBlockBtn").addClass("comparison__mobBlockBtn--active")
    $(".comparisonMobBtns").addClass("comparisonMobBtns--edit")
})

$(".comparison__mobBlockBtn").on("click", function (e) {
    $(this).removeClass("comparison__mobBlockBtn--active")
    $(".comparison__mobBlockBtn2").addClass("comparison__mobBlockBtn2--active")
    $(".comparisonMobBtns").removeClass("comparisonMobBtns--edit")
})

$(".catalogList__more").on("click", function (e) {
    $(this).closest(".catalogList__content").find(".catalogList__list").addClass("catalogList__list--active")
    $(this).closest(".catalogList__categoryMob").find(".catalogList__list").addClass("catalogList__list--active")
    $(this).remove()
})

// let x;
// addEventListener('touchstart', e => x = e.changedTouches[0].clientX);
// addEventListener('touchend', e => e.changedTouches[0].clientX - x < -50 && swipeLeft());

// function swipeLeft() {
//    console.log('swipe left')
//    swiper7.slidePrev(1000)
// }

$(".rating--active").on('click', '.rating__star', function () {
    let l = $(this).index() * 20 + 20
    $(this).closest(".rating").find(".rating__filled").width(l + "%")
});

$('.page__body').on("click", '.catalogPage__mobFiltersBtn ', function (e) {
    if (this.querySelector(".icon-tune.catalogPage__mobFiltersBtnIcon")) document.querySelector(".graph-modal").style.zIndex = 9999;
})


document.addEventListener("click", function (event) {
    const clueClueMin = document.querySelectorAll(".clue__clue.clue__clue--active");
    clueClueMin.forEach((clueClueMinOne, i) => {
        if ((!event.target.classList.contains("productCard__bonus") && !event.target.classList.contains("catalogCollectionCardProduct__bonus")) && clueClueMinOne && !clueClueMinOne.classList.contains(event.target) && (!event.target.classList.contains("clue__info") || !clueClueMinOne.contains(event.target.querySelector(".clue__clue")))) {
            clueClueMinOne.classList.remove("clue__clue--active");
            let info = clueClueMinOne.closest(".clue__info--active");
            if(info)
                info.classList.remove("clue__info--active");
        }
    });

});


let g_timer = null;

$(".productCard__bonus, .catalogCollectionCardProduct__bonus, .basketProdCard__priceBonus, .basketForm__paymentDescrText2").on("click", function (e) {
    clearTimeout(g_timer);
    startTimer(e, this);


    g_timer = window.setTimeout(() => {
        $(this).find(".clue__clue").removeClass("clue__clue--active")
        // setTimeout(() => {
        //    $(this).find(".clue__clue").removeClass("clue__clue--bottom")
        // }, 400);

    }, 5000);
})

function startTimer(e, clue) {
    // if (e.target == clue) {
    $(".clue__clue").removeClass("clue__clue--active")
    $(clue).find(".clue__clue").addClass("clue__clue--active")
    if ($(window).height() - ($(clue).find(".clue__clue").outerHeight() + $(clue).find(".clue__clue").offset().top - $(window).scrollTop()) > 50) {
        $(clue).find(".clue__clue").removeClass("clue__clue--bottom")
    } else {
        $(clue).find(".clue__clue").addClass("clue__clue--bottom")
    }
    // }
}

$(".clue__clue").on("click", function (e) {
    e.stopPropagation()
})

$(".catalogList__arrow").on("click", function (e) {
    $(this).closest(".catalogList__categoryWrap").toggleClass("catalogList__categoryWrap--active")
    $(this).closest(".catalogList__categoryMob").find(".catalogList__categorContent").slideToggle(400)
})

// Подключение свайпера
import Swiper from 'swiper/bundle';

const swiper = new Swiper(".sliderMain", {
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 7500,
        disableOnInteraction: false,
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        1026: {
            slidesPerView: "auto",
            spaceBetween: 20,
        },
    },
    navigation: {
        nextEl: ".mainSlider .swiper-button-next-custom",
        prevEl: ".mainSlider .swiper-button-prev-custom",
    },
    on: {
        init() {
            this.el.addEventListener('mouseenter', () => {
                this.autoplay.stop();
            });

            this.el.addEventListener('mouseleave', () => {
                this.autoplay.start();
            });
        }
    },
});

const swiper2 = new Swiper(".sliderCardProductFilter", {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    on: {
        init() {
            this.el.addEventListener('mouseenter', () => {
                this.autoplay.stop();
            });

            this.el.addEventListener('mouseleave', () => {
                this.autoplay.start();
            });
        }
    },
    pagination: {
        el: ".cardProductFilterSlider .swiper-pagination",
        clickable: true,
    },
});

const swiper3 = new Swiper(".catalogPageSlider", {
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 20,
    speed: 1000,
    navigation: {
        nextEl: ".catalogPageSlider ~ .swiper-button-next-custom",
        prevEl: ".catalogPageSlider ~ .swiper-button-prev-custom",
    },
    breakpoints: {
        0: {
            spaceBetween: 10,
        },
        770: {
            spaceBetween: 20,
        },
    },
    on: {
        afterInit: () => {
            this.navigation.update()
        }
    }
});

const swiper4 = new Swiper(".sliderBrend", {
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 7500,
        disableOnInteraction: false,
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        1026: {
            slidesPerView: "auto",
            spaceBetween: 20,
        },
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    on: {
        init() {
            this.el.addEventListener('mouseenter', () => {
                this.autoplay.stop();
            });

            this.el.addEventListener('mouseleave', () => {
                this.autoplay.start();
            });
        },
        // afterInit: () => {
        //    this.navigation.update()
        // }
    },
});
window["swiper"] = [];
window.initSliderProdMin = function() {
    document.querySelectorAll(".sliderProdMin:not(.sliderProdMin--init)").forEach((element) => {
        element.classList.add("sliderProdMin--init");
        window["swiper"][(element.id ? element.id : "def")] = new Swiper(element, {
            loop: false,
            /*lazy: {
                loadPrevNext: true,
            },*/
            slidesPerView: "auto",
            spaceBetween: 20,
            speed: 1000,
            autoplay: "false",
            breakpoints: {
                0: {
                    spaceBetween: 10,
                },
                1026: {
                    spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: element.closest(".sliderNavWrap").querySelector(".swiper-button-next-custom"),
                prevEl: element.closest(".sliderNavWrap").querySelector(".swiper-button-prev-custom"),
            },
        });
    });
}
window.initSliderProdMin();

let swiper7 = new Swiper(".sliderComparison", {
    loop: false,
    slidesPerView: "auto",
    speed: 1000,
    autoplay: "false",
    spaceBetween: 0,
    allowTouchMove: false,
    navigation: {
        nextEl: ".swiper-button-next-custom",
        prevEl: ".swiper-button-prev-custom",
    },
    on: {
        init() {
            let this2 = this
            let x;
            addEventListener('touchstart', e => x = e.changedTouches[0].clientX);
            addEventListener('touchend', e => e.changedTouches[0].clientX - x < -50 && swipeLeft());
            addEventListener('touchend', e => e.changedTouches[0].clientX - x > 50 && swipeRight());

            function swipeLeft(e) {
                this2.slideNext(1000)
            }

            function swipeRight(e) {
                this2.slidePrev(1000)
            }
        }
    },
});

/* Added Pavel I.*/
window.decodeHtml = (text) => {
    return text.toString().replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, "\"").replace(/&#039;/g, "'");
}

window.loadLazyData = () => {
    document.querySelectorAll("[data-lazy_data]").forEach((element) => {
        element.innerHTML = decodeHtml(element.dataset['lazy_data']);
        element.removeAttribute('data-lazy_data');
    });
}
window.loadLazyDataAppend = () => {
    document.querySelectorAll("[data-lazy_data_append]").forEach((element) => {
        element.innerHTML += decodeHtml(element.dataset['lazy_data_append']);
        element.removeAttribute('data-lazy_data_append');
    });
}
window.loadLazyDataPrepend = () => {
    document.querySelectorAll("[data-lazy_data_prepend]").forEach((element) => {
        element.innerHTML = decodeHtml(element.dataset['lazy_data_prepend']) + element.innerHTML;
        element.removeAttribute('data-lazy_data_prepend');
    });
}
//TODO to preload
window.loadLazyDataMain = () => {
    loadLazyData();
    loadLazyDataAppend();
    loadLazyDataPrepend();
}

window.openCatalog = () => {
    let event;
    let element = document.querySelector(".headerMiddle__btnCatalog");
    if(document.createEvent){
        event = document.createEvent("HTMLEvents");
        event.initEvent("click", true, true);
        event.eventName = "click";
        element.dispatchEvent(event);
    } else {
        event = document.createEventObject();
        event.eventName = "click";
        event.eventType = "click";
        element.fireEvent("on" + event.eventType, event);
    }
}

loadLazyDataMain();
