/*
* TODO:rewrite without jquery
* */
function clearPageFromUrl(url) {
    url = url.replace(/page=[0-9]*/, "");
    url = url.replace('?&', '?');
    url = url.replace('&&', '&');
    url = url.replace(/\?$/, '');
    return url;

}

function getUrlChangePage() {
    let u, url,
        page = window.catalog_params.page;
    u = window.location.href;
    u = u.replace(window.location.protocol + '//' + window.location.host, "");
    if (page > 1) {
        if (u.indexOf("page=") > 0) {
            url = u.replace(/page=[0-9]*/, "page=" + page);
        } else {
            if (u.indexOf("?") > 0) {
                url = u + "&page=" + page;
            } else {
                url = u + "?page=" + page;
            }
        }
    } else if (u.indexOf("page=") > 0) {
        url = clearPageFromUrl(u);
    }
    console.log(url);
    return url;
}

function getUrlChangeSort() {
    let u, selectedsort;
    if (!window.catalog_params.no_replace_url_by_filter)
        u = window.location.href;
    else
        u = getUrlByForm();
    u = u.replace(window.location.protocol + '//' + window.location.host, "");
    u = u.replace('/#', "");
    selectedsort = $('.catalogPageCatalogSort__btn.catalogPageCatalogSort__btn--active');
    if (selectedsort.length && selectedsort.val().length) {
        if (u.indexOf("?") > 0) {
            if (u.indexOf("sort=") > 0)
                u = u.replace(/sort=[^&]*/, "sort=" + selectedsort.attr('value'));
            else
                u = u + "&sort=" + selectedsort.attr('value');
            if (u.indexOf("sort_dir=") > 0)
                u = u.replace(/sort_dir=[^&]*/, "sort_dir=" + selectedsort.attr('data-dir'));
            else
                u = u + "&sort_dir=" + selectedsort.attr('dir');
            url = u;
        } else {
            url = u + "?sort=" + selectedsort.attr('value') + "&sort_dir=" + selectedsort.attr('data-dir');
        }
    } else {
        if (u.indexOf("?") > 0) {
            if (u.indexOf("sort=") > 0)
                u = u.replace(/\&?sort=[^&]*/, "");
            if (u.indexOf("sort_dir=") > 0)
                u = u.replace(/\&?sort_dir=[^&]*/, "");
            u = u.replace(/\?\&$/, "");
        }
        url = u;
    }
    url = clearPageFromUrl(url);

    url = url.replace('//', '/');
    url = url.replace(/\?$/, '');
    //console.log(url);
    return url;
}

function getUrlByForm() {
    let counturlin = 0;
    let counturlcur = 0;
    let u, s, after;
    if (typeof window.catalog_params.counturlin !== "undefined")
        counturlin = window.catalog_params.counturlin;

    if ($('.catalogPageFilter > .filters').length === 0) {
        u = window.location.href;
        u = u.replace(window.location.protocol + '//' + window.location.host, "");
        if (u.indexOf("?") > 0)
            u = u.substring(0, u.indexOf("?"));
        console.log(1);
    } else {
        u = $("#base_url").val();
        console.log(2);
    }
    u = u + '/';
    u = u.replace("//", "/");
    s = "";
    after = "";

    let selectedSort = $('.catalogPageCatalogSort__btn.catalogPageCatalogSort__btn--active[value]');
    let at = [];  // Атрибуты URL (Query - параметры)
    let subCat = []; // массив для типов мебели
    if (window.catalog_params.page > 1) {
        at.push("page=" + window.catalog_params.page);
    }
    if (location.search.match(/q=([^&]+)/) != null) {
        at.push("q=" + location.search.match(/q=([^&]+)/)[1]);
    }
    if (selectedSort.length) {
        at.push("sort=" + selectedSort.val());
        at.push("sort_dir=" + selectedSort.attr('data-dir'));
    }

    if ($('.catalogPageFilter .add_to_url').length === 1) {

        u = u + $('.catalogPageFilter .add_to_url').eq(0).attr("data-urlslug") + '/';
        counturlcur++;
    }

    if ($('.catalogPageFilter .add_to_url_after').length === 1) {
        after = $('.catalogPageFilter .add_to_url_after').eq(0).attr("data-urlslug") + "/";
        counturlcur++;
    }

    $('.catalogPageFilter > .filters').each(function () {

        if ($(this).find('input.refreshlist:checked').length === 1) {
            let attr = $(this).find('input.refreshlist:checked').eq(0);
            console.log(attr);
            let attr1 = $(attr).attr("data-urlslug");
            // For some browsers, `attr` is undefined; for others,
            // `attr` is false.  Check for both.
            if (attr1 && (counturlin === 0 || counturlcur < counturlin)) {
                u = u + attr1 + '/';
                counturlcur++;
            } else {
                if ($(attr).attr('name') === 'subcategory[]')
                    subCat.push($(attr).attr('name') + "=" + $(attr).val());
                else
                    at.push($(attr).attr('name') + "=" + $(attr).val());
            }
        } else {
            $(this).find('input.refreshlist:checked').each(function () {
                if ($(this).attr('name') === 'subcategory[]')
                    subCat.push($(this).attr('name') + "=" + $(this).val());
                else
                    at.push($(this).attr('name') + "=" + $(this).val());
            });
        }
        $(this).find('input.refreshlist_input').each(function () {
            if ($(this).val() != '')
                at.push($(this).attr('name') + "=" + $(this).val());
        });
    });

    // if ($('.view_as input[name=collection]:checked').length) {
    //     if ($('.view_as input[name=collection]:checked').val() == '1')
    //         at.push('collection=1');
    //     else {
    //         var c1 = at.indexOf('collection=1');
    //         c1 >= 0 ? at.splice(c1, 1) : '';
    //     }
    // }
    u = u + after + s + '/';
    u = u.replace('//', '/');

    at = at.concat(subCat);
    if (at.length > 0)
        u = u + '?' + at.join('&');
    return u;
}

function getFiltersByForm(all) {
    if (typeof all == "undefined")
        all = false;

    let data = {
        filters: {},
        ftns: [],
        parts: [],
        sort_dir: null,
        sort: null,
        search: false,
        page: window.catalog_params.page
    };

    let counturlin = 0;
    let counturlcur = 0;

    if (typeof window.catalog_params.counturlin !== "undefined")
        counturlin = window.catalog_params.counturlin;

    let selectedSort = $('.catalogPageCatalogSort__btn.catalogPageCatalogSort__btn--active[value]');

    if (location.search.match(/q=([^&]+)/) != null) {
        data.search = location.search.match(/q=([^&]+)/)[1];
    }
    if (selectedSort.length) {
        data.sort_dir = selectedSort.attr('data-dir');
        data.sort = selectedSort.val();
    }

    $(".catalogPageFilter .filter_silent").each(function () {
        let name_filter = $(this).attr('name');
        if (name_filter.indexOf("[]") > 0) {
            name_filter = name_filter.replace("[]", "");
            if (!data.filters[name_filter])
                data.filters[name_filter] = [];
            data.filters[name_filter].push($(this).val());
        } else {
            data.filters[name_filter] = $(this).val();
        }
    })

    $(".catalogPageFilter > .filters").each(function () {

        if ($(this).find('input.refreshlist:checked').length === 1) {
            let attr = $(this).find('input.refreshlist:checked').eq(0);
            let attr1 = $(attr).attr("data-urlslug");
            if (attr1 && (counturlin === 0 || counturlcur < counturlin)) {
                data.parts.push({
                    name: attr.attr("name").replace("[]", ""),
                    slug: attr1,
                    value: attr.val()
                });
                counturlcur++;
            }
        }
    });
    $(".catalogPageFilter input[data-attr_id='ftn_at']").each(function () {
        data.ftns.push($(this).val());
    })

    $(".catalogPageFilter > .filters").each(function () {
        if ($(this).find('input.refreshlist:checked').length) {
            $(this).find('input.refreshlist:checked').each(function () {
                let name_filter = $(this).attr('name');
                if (name_filter.indexOf("[]") > 0) {
                    name_filter = name_filter.replace("[]", "");
                    let vals = [$(this).val()];
                    if (!data.filters[name_filter])
                        data.filters[name_filter] = [];
                    if ($(this).attr("data-attr_id") == 'ftn_at') {
                        vals = $(this).val().split("_");
                    }
                    data.filters[name_filter] = data.filters[name_filter].concat(vals);
                } else {
                    data.filters[name_filter] = $(this).val();
                }
            });
        } else if (all && $(this).find('input.refreshlist').length) {
            let $attr = $(this).find('input.refreshlist').eq(0);
            let name_filter = $attr.attr('name');
            if (name_filter.indexOf("[]") > 0) {
                name_filter = name_filter.replace("[]", "");
                //if (!data.filters[name_filter])
                //    data.filters[name_filter] = [];
                //data.filters[name_filter].push("");
            }
            data.filters[name_filter] = null;

        }

        $(this).find("input.refreshlist_input").each(function () {
            let name = $(this).attr('name');
            if (all || $(this).val() != '') {
                if (name.indexOf("_min") != -1) {
                    let name_filter = name.replace("_min", "");
                    if (!data.filters[name_filter])
                        data.filters[name_filter] = {"min": -1, "max": -1};
                    if ($(this).val() != '')
                        data.filters[name_filter]["min"] = $(this).val();
                } else if (name.indexOf("_max") != -1) {
                    let name_filter = name.replace("_max", "");
                    if (!data.filters[name_filter])
                        data.filters[name_filter] = {"min": -1, "max": -1};
                    if ($(this).val() != '')
                        data.filters[name_filter]["max"] = $(this).val();
                }
            }
        });
    });
// TODO do it for plitka
// if ($('.view_as input[name=collection]:checked').length) {
//     if ($('.view_as input[name=collection]:checked').val() == '1')
//         at.push('collection=1');
//     else {
//         var c1 = at.indexOf('collection=1');
//         c1 >= 0 ? at.splice(c1, 1) : '';
//     }
// }
    data["_token"] = $('meta[name="csrf-token"]').attr('content');
    return data;
}

function setBackgdound(s) {
    window.catalog_params.page = 1;
    if (window.catalog_params.keytimeout)
        clearTimeout(window.catalog_params.keytimeout);
    window.catalog_params.keytimeout = setTimeout(function () {
        refreshList(true)
    }, 2);
}

function refreshList(refresh, url, obj) {
    console.log("refresh list");
    let no_filters = 0;
    let skip_layout = 1;
    try {
        let search = '?q=' + decodeURI(/q=(.[^&]+)/.exec(window.location.search)[1]).replace(/\+/, ' ') + '&';
    } catch (e) {
        search = '?';
    }
    if (typeof url === "undefined") {
        if (refresh === 'more') {
            url = getUrlChangePage();
            if (!window.catalog_params.no_replace_url_by_filter)
                window.history.pushState(null, null, url);
            refresh = false;
            no_filters = 1;
            skip_layout = 1;
        } else {
            url = getUrlByForm();
            if (!window.catalog_params.no_replace_url_by_filter)
                window.history.pushState(null, null, url);
        }
    } else {
        if (!window.catalog_params.no_replace_url_by_filter)
            window.history.pushState(null, null, url);
        no_filters = 1;
        skip_layout = 1;
    }
    /*if (window.catalog_params.hidden_filter) {
        url = "/" + window.catalog_params.hidden_filter + url;
    }*/

    if (window.catalog_params.jqrefresh) {
        window.catalog_params.jqrefresh.abort();
        window.catalog_params.jqrefresh = null;
    }

    let data_send = getFiltersByForm(false);
    data_send['no_filters'] = no_filters;
    data_send['skip_layout'] = skip_layout;
    data_send['url'] = clearPageFromUrl(url);
    data_send['isset_brands_abc'] = 0;
    if ($("#catalog_title_su_brands .catalogPageBrends").length) {
        data_send['isset_brands_abc'] = 1;
    }
    //$('.catalog-items-block').addClass('loaded');

    window.catalog_params.jqrefresh = $.post(window.catalog_params.post.products, data_send)
        .done(function (data) {
            if (!window.catalog_params.hidden_pagination && typeof data.pagination_block != "undefined") {
                $("#pagination_block").html(data.pagination_block);
            }
            if (typeof data.more != "undefined") {
                if (data.more)
                    $("#catalog_show_more").removeClass("is-hidden")
                else
                    $("#catalog_show_more").addClass("is-hidden")
            }
            if (!no_filters)
                updatePageData(0);
            if (refresh !== false) {
                if (data.is_collections)
                    $(".productCards").empty();
                else
                    $(".catalogPageCatalog__productCards").empty();
            }
            //console.log(data.breadcrumbs);
            if (data.breadcrumbs && $("#breadCrumbs").length) {
                $("#breadCrumbs").html($(data.breadcrumbs).contents());
            }
            if (typeof data.show_brands_abc !== "undefined") {
                if (data.show_brands_abc) {
                    if (data_send['isset_brands_abc']) {
                        $("#catalog_title_su_brands .catalogPageBrends").removeClass("is-hidden");
                    } else if (data.brands_abc) {
                        initBrandsABC(data.brands_abc);
                    }
                } else if (data_send['isset_brands_abc']) {
                    $("#catalog_title_su_brands .catalogPageBrends").addClass("is-hidden");
                }
            }
            if (typeof data.su_cached !== "undefined") {
                $("#su_popular .catalogPageTabs__list").empty();
                $("#su_other .catalogPageTabs__list").empty();
                if (data.su_cached.popular) {
                    $("#su_popular .catalogPageTabs__nameMob").text("Популярные категории:");
                    let html = getSuList(data.su_cached.popular, true);
                    $("#su_popular .catalogPageTabs__list").append(html);
                    $("#su_popular").removeClass("is-hidden");
                } else {
                    $("#su_popular").addClass("is-hidden");
                }
                if (data.su_cached.other) {
                    $("#su_other .catalogPageTabs__title").text("Популярные подборки");
                    let html = getSuList(data.su_cached.other, false);
                    $("#su_other .catalogPageTabs__list").append(html);
                    $("#su_other").removeClass("is-hidden");
                } else {
                    $("#su_other").addClass("is-hidden");
                }
                window.initCatalogPageTabs__more();
            }

            if (data.h1)
                $("h1").text(data.h1);
            if (data.products) {
                data.products.forEach((el) => {
                    if (data.is_collections)
                        addTileTolist(el, data.page);
                    else
                        addProductTolist(el, data.page);
                })
                if (data.is_collections)
                    window.updateShowPreviewPlitka();
            }
        })
        .fail(function (data, status) {
            console.log(data);
            if (data.status === 419) {
                //location.reload();
            }
        })
        .always(function () {
            window.catalog_params.jqrefresh = null;
            $("#catalog_show_more").prop("disabled", false);
            console.log("always refreshList");
        });
    /* window.catalog_params.jqrefresh = $.post(window.catalog_params.post.products, data,
         function (data, status, x) {
             console.log(data);
             console.log(status);
             // $('.catalog-items-block').removeClass('loaded');
             // console.log(data.length);
             // jqrefresh = null;
             // if (status == "success") {
             //     if (refresh) {
             //         //var clone_stat1 = $("#stat_url_1_level .groups-catalog-items-below").clone();
             //         var clone_brand_production = false;
             //         if ($(".catalog-items-block .brand_production").length && $("input[name='brand[]']:checked").length)
             //             clone_brand_production = $(".catalog-items-block .brand_production").clone();
             //         $('.catalog-items-block').html(data);
             //         //$("#stat_url_1_level").html(clone_stat1);
             //         if (clone_brand_production !== false && !$('.catalog-items-block .brand_production').length) {
             //             if ($('.catalog-items-block .container').length)
             //                 $('.catalog-items-block .container').before(clone_brand_production);
             //             else
             //                 $('.catalog-items-block').append(clone_brand_production);
             //             delete clone_brand_production;
             //         }
             //         if (no_filters) {
             //             if (typeof scrollTracking == "function") {
             //                 setTimeout(scrollTracking, 300);
             //             }
             //             if (typeof copy_favorite == "function") {
                 //                 copy_favorite();
             //             }
             //             if (typeof show_bonus == "function") {
             //                 show_bonus();
             //             }
             //             initSliderProduct();
             //             initSliderProductElem();
             //             $(window).resize();
             //             return;
             //         }
             //         //delete clone_stat1;
             //     } else {
             //         $answer = $(data);
             //         $answer.find(".hidden").remove();
             //         $('.catalog-items-block .product_wr').last().after($answer);
             //         updatePagination(page, data);
             //         if (typeof scrollTracking == "function") {
             //             setTimeout(scrollTracking, 300);
             //         }
             //         if (typeof copy_favorite == "function") {
             //             copy_favorite();
             //         }
             //         if (typeof show_bonus == "function") {
             //             show_bonus();
             //         }
             //         initSliderProduct();
             //         initSliderProductElem();
             //         $(window).resize();
             //         return;
             //     }
             //
             //
             //     if (!window.catalog_params.no_replace_url_by_filter) {
             //         //update brand grayed
             //         if ($('.ajax_data .wtitle').length > 0) {
             //             document.title = $('.ajax_data .wtitle').eq($('.ajax_data .wtitle').length - 1).text();
             //         }
             //
             //         //update breadcumbs
             //         if ($('.ajax_data .breadcrumbs_ajax').length > 0 && $('.ajax_data .breadcrumbs_ajax .main_breadcrumbs ul').html() != "") {
             //             var clon_bread = $('.ajax_data .breadcrumbs_ajax .main_breadcrumbs ul').clone();
             //             $('.ajax_data .breadcrumbs_ajax .main_breadcrumbs').remove();
             //             $(".main_breadcrumbs ul").html(clon_bread.html());
             //             delete clon_bread;
             //         }
             //     }
             //
             //     checkoff = true;
             //     if ($('.product_wr .count_items').text() == 0) {
             //         checkoff = false;
             //     }
             //     //update collection
             //     updateAttributeCounter('=brand', '.brands', checkoff);
             //     if ($('.ajax_data .collects').length > 0) {
             //         var collects = $('.ajax_data .collects').html();
             //         dc = collects == '' ? {} : JSON.parse(collects);
             //         dd = Object.keys(dc);
             //
             //         if (dd.length == 1) {
             //             for (i = 0; i < dd.length; i++) {
             //                 cur_brand = $("#collection_filter").attr("data-brand");
             //                 if (cur_brand == dd[i])
             //                     break;
             //                 console.log("rend collections");
             //                 unsetSelCollection();
             //                 $("#collection_filter").attr("data-brand", dd[i]);
             //                 di = Object.keys(dc[dd[i]]);
             //                 if (di.length)
             //                     $("#collection_filter").removeClass('hidden');
             //                 sortA = [];
             //                 for (ii = 0; ii < di.length; ii++) {
             //                     sortA[di[ii]] = [di[ii], dc[dd[i]][di[ii]][0], dc[dd[i]][di[ii]][1], dc[dd[i]][di[ii]][2]];
             //                 }
             //                 sort(sortA);
             //
             //                 function sort(arr) {
             //                     arr.sort(function (a, b) {
             //                         var nameA = a[1].toLowerCase(), nameB = b[1].toLowerCase();
             //                         if (nameA < nameB) //sort string ascending
             //                             return -1;
             //                         if (nameA > nameB)
             //                             return 1;
             //                         return 0; //default return value (no sorting)
             //                     });
             //                 }
             //
             //                 for (ii = 0; ii < di.length; ii++) {
             //                     var input = "";
             //                     //if($("#collection_filter").data("type") != "undifined" && $("#collection_filter").data("type") != "plitka")
             //                     input = '<input hidden="" type="checkbox" class="refreshlist filter" attr_id="collects" value="' + sortA[ii][0] + '" urlslug="' + sortA[ii][2] + '" name="collects[]">';
             //                     ;
             //                     $("#collection_filter .for_scroll").append(
             //                         '<div class="input">' +
             //                         '<label>' +
             //                         input +
             //                         '<span></span>' +
             //                         '<p>' + sortA[ii][1] + '</p>' +
             //                         '</label>' +
             //                         '</div>'
             //                     );
             //                 }
             //             }
             //         } else {
             //             unsetSelCollection();
             //         }
             //     }
             //     //update attr grayed
             //     updateAttributeCounter('!=brand', '.attr', checkoff);
             //     //update count collection
             //     updateAttributeCounter('=collects', '.accollection', checkoff);
             //     //update count countries
             //     updateAttributeCounter('=country', '.countries', checkoff);
             //     // update count subcategories
             //     updateAttributeCounter('=subcategory', '.subcategories', checkoff);
             //     // update furniture attr
             //     updateAttributeCounter('=ftn_at', '.ftn_attr', checkoff);
             //     if (!window.catalog_params.no_replace_url_by_filter) {
             //         //update page title H1
             //         if ($('.ajax_data .title').length)
             //             $('.catalog_title .left_info h1').html($('.ajax_data .title').html());
             //     }
             //     $(".main_filter .filter_top p").text($('.ajax_data .minmax').text());
             //     if (obj) {
             //         console.log(obj);
             //
             //         $('.hint').removeAttr('style');
             //         var checkbox = obj;
             //         var position = obj.closest('.input').offset();
             //
             //         var left = position.left;
             //         var top = position.top;
             //
             //         if (checkbox.is(':checked')) {
             //             $('.hint').offset({top: top, left: left});
             //             $('.hint').css({"margin-top": "-59px", "margin-left": "-230px", "display": "block"});
             //             $('.hint .for_triangle').text($('.ajax_data .minmax').text());
             //             $('.hint').show();
             //             hinttimeout = setTimeout(function () {
             //                 $('.hint').fadeOut("slow");
             //             }, 2000);
             //         } else {
             //             $('.hint').hide();
             //         }
             //     }
             //     //update breadcrumbs and groups
             //     /!*			updateToMoveBlocks();
             //     *!/			//hideCounter();
             //     if (!window.catalog_params.no_replace_url_by_filter) {
             //         //update shop_catalogs
             //         $(".catalog_title .left_info .catalogs_btn:not(.load_catalog_privilege)").remove();
             //         if ($('.ajax_data .shop_catalogs').length && $('.ajax_data .shop_catalogs').html() != "") {
             //             var list_catalogs = JSON.parse($('.ajax_data .shop_catalogs').html());
             //             if (list_catalogs.length) {
             //                 if (false && list_catalogs.length == 1) {
             //                     $(".catalog_title .left_info > div").append('<a href="/upload/media/shop_catalogs/' + list_catalogs[0]['file'] + '" target="_blank" rel="nofollow" class="main_btn catalogs_btn purple_btn">Каталог продукции</a>');
             //                 } else {
             //                     $(".catalog_title .left_info > div").append('<div class="main_btn catalogs_btn show_catalogs purple_btn" data-catalogs=\'' + $('.ajax_data .shop_catalogs').html().replace(/'/g, "\'") + '\'>Каталоги продукции</div>');
             //                 }
             //             }
             //         }
             //
             //         //update btn_top10_brands
             //         $(".catalog_title .top10_brands_btn").remove();
             //         if ($('.ajax_data .top10_brands').length) {
             //             $(".catalog_title .left_info > div").prepend('<a href="' + $('.ajax_data .top10_brands').attr("data-url") + '" class="main_btn catalogs_btn top10_brands_btn">' + $('.ajax_data .top10_brands').text() + '</a>');
             //         }
             //
             //
             //         //update btn_feedback
             //         if ($('.ajax_data .shop_feedback').length) {
             //             if (!$(".catalog_title .feedback_btn").length) {
             //                 $(".catalog_title .left_info > div").prepend('<a href="' + $('.ajax_data .shop_feedback').attr("data-url") + '" class="main_btn catalogs_btn feedback_btn">Отзывы</a>');
             //             }
             //         } else {
             //             $(".catalog_title .feedback_btn").remove();
             //         }
             //
             //         //update stat url level2
             //         if ($('.ajax_data .groups-catalog-items-below').length) {
             //             /!*if($("#stat_url_2_level .groups-catalog-items-below").hasClass('thisisstat1')){
             //                 stat1toleft();
             //             }*!/
             //             $("#stat_url_2_level").empty();
             //             $("#stat_url_2_level").append($('.ajax_data .groups-catalog-items-below'));
             //             update_block_stat_urls();
             //         } else {
             //             $("#stat_url_2_level").empty();
             //             /!*
             //             if(checkMainCat()){
             //                 if(!$("#stat_url_2_level .groups-catalog-items-below").hasClass('thisisstat1')){
             //                     $("#stat_url_2_level").empty();
             //                     stat1toup();
             //                 }
             //             }
             //             else{
             //                 if($("#stat_url_2_level .groups-catalog-items-below").hasClass('thisisstat1')){
             //                     stat1toleft();
             //                 }
             //                 $("#stat_url_2_level").empty();
             //             }*!/
             //         }
             //
             //         //update stat url h1
             //         if ($('.ajax_data .groups-catalog_h1').length) {
             //             $(".catalog_title .groups-catalog_h1").remove();
             //             $('.ajax_data .groups-catalog_h1').insertAfter($(".catalog_title > .left_info"));
             //             $(window).resize();
             //         } else {
             //             $(".groups-catalog_h1").remove();
             //         }
             //
             //         //update collections_urls
             //         if ($('.ajax_data .groups-catalog-items-below_collections').length) {
             //             if ($(".main_catalogue .sorting_and_collections_row").length) {
             //                 if (!$(".main_catalogue .sorting_and_collections_row #collection_block_urls").length) {
             //                     $(".main_catalogue .sorting_and_collections_row").prepend('<div id="collection_block_urls"></div>');
             //                 }
             //                 $("#collection_block_urls").empty();
             //                 $("#collection_block_urls").append($('.ajax_data .groups-catalog-items-below_collections'));
             //                 $("#collection_block_urls .groups-catalog-items-below_collections").addClass("groups-catalog-items-below").removeClass("groups-catalog-items-below_collections");
             //             }
             //         } else {
             //             $("#collection_block_urls").remove();
             //         }
             //
             //         if ($('.ajax_data .data_brands_abc').length && $(".main_filter .brands input[name='brand[]']").length) {
             //             var brands_abc = "";
             //             if (!$(".main_catalogue .brands_abc").length)
             //                 brands_abc += '<div class="brands_abc">';
             //             var brand_id = false;
             //             if ($('.ajax_data .data_brands_abc').attr("brand_id"))
             //                 brand_id = $('.ajax_data .data_brands_abc').attr("brand_id");
             //             var title_abc = "Бренды";
             //             if (brand_id)
             //                 title_abc = "Другие бренды";
             //             brands_abc += '<div class="brand_abc_title">' + title_abc + ':</div><div class="brand_abc_items">';
             //             $(".main_filter .brands input[name='brand[]']").eq(0).closest(".for_scroll").find(".letter_wr").each(function () {
             //                 var letter = $(this).find('.letter').text();
             //                 var class_name = "";
             //                 if ((/[А-я]./).test(letter))
             //                     class_name = "rus_abs";
             //
             //                 brands_abc_temp = "";
             //                 $(this).find(".input").each(function () {
             //                     if (brand_id && brand_id == $(this).find('input').val()) return true;
             //                     brands_abc_temp += '<li><a href="' + $('.ajax_data .data_brands_abc').text() + $(this).find('input').attr('urlslug') + '/">' + $(this).find('p').text() + '</a></li>'
             //                 });
             //                 if (brands_abc_temp != "") {
             //                     brands_abc += "<div class='" + class_name + "'>" + letter + "<ul>";
             //                     brands_abc += brands_abc_temp;
             //                     brands_abc += "</ul></div>";
             //                 }
             //             });
             //             brands_abc += "</div>";
             //             if (!$(".main_catalogue .brands_abc").length) {
             //                 brands_abc += "</div>";
             //                 $(".catalog_wr").before(brands_abc);
             //             } else {
             //                 $(".main_catalogue .brands_abc").html(brands_abc);
             //             }
             //
             //             delete brands_abc;
             //             //}
             //         } else {
             //             $(".main_catalogue .brands_abc").remove();
             //         }
             //         if ($('.ajax_data .data_brand_logo').length) {
             //             var data_logo = JSON.parse($('.ajax_data .data_brand_logo').html());
             //             var need_need_add_logo = true;
             //             if ($(".main_filter .filter_logo").length) {
             //                 if ($(".main_filter .filter_logo img").attr("src") == data_logo['src'])
             //                     need_need_add_logo = false;
             //                 else
             //                     $(".main_filter .filter_logo").remove();
             //             }
             //             if (need_need_add_logo) {
             //                 $(".main_filter").prepend('<div class="filter_logo"><img src="' + data_logo['src'] + '" alt="' + data_logo['alt'] + '" title="' + data_logo['title'] + '"></div>');
             //             }
             //         } else {
             //             $(".main_filter .filter_logo").remove();
             //         }
             //
             //         if ($('.ajax_data .minmax_json').length && $('.ajax_data .minmax_json').text()) {
             //             let minmax = JSON.parse($('.ajax_data .minmax_json').text());
             //             if (minmax) {
             //                 for (index in minmax) {
             //                     if (minmax[index] !== null) {
             //                         if ((/_max/).test(index) || (/max_/).test(index)) {
             //                             $(".main_filter input[name=" + index + "]").attr("placeholder", "До " + minmax[index]);
             //                         }
             //                         if ((/_min/).test(index) || (/min_/).test(index)) {
             //                             $(".main_filter input[name=" + index + "]").attr("placeholder", "От " + minmax[index]);
             //                         }
             //                     }
             //                 }
             //                 ;
             //             }
             //             $('.ajax_data .minmax_json').empty();
             //         }
             //
             //         $(".cat_product > .container.bottom_text").remove();
             //         if ($('.ajax_data .bottom_text_loaded .cat_text').length) {
             //             $(".cat_product #stat_url_2_level").after($('.ajax_data .bottom_text_loaded').html());
             //             $('.ajax_data .bottom_text_loaded').remove();
             //         }
             //
             //     }
             //     if ($(".catalog_title .left_info .catalogs_btn.load_catalog_privilege").length) {
             //         if ($(".filter_block input[name='brand[]']:checked").length)
             //             $(".catalog_title .left_info .catalogs_btn.load_catalog_privilege").show();
             //         else
             //             $(".catalog_title .left_info .catalogs_btn.load_catalog_privilege").hide();
             //     }
             //     //очистка формы поиска бренда
             //     $(".search_brand").val("").keyup()
             //     //compare
             //     //productsSliderInit();
             //
             //     // #bottom-slider-wrap
             //     if (!window.catalog_params.no_replace_url_by_filter)
             //         App.init_ajax_data();
             //     if (typeof scrollTracking == "function") {
             //         setTimeout(scrollTracking, 300);
             //     }
             //     if (typeof copy_favorite == "function") {
             //         copy_favorite();
             //     }
             //     if (typeof show_bonus == "function") {
             //         show_bonus();
             //     }
             //     initSliderProduct();
             //     initSliderProductElem();
             // }
             //
             // $(window).resize();
         }, 'json');*/

    console.log(window.catalog_params.jqrefresh);
}

function getSuList(su, is_popular) {
    let html = "";
    for (let group in su["links"]) {
        if (is_popular)
            html += '<div class="catalogPageTabs__item catalogPageTabs__item--nameCategory">' + group + ':</div>';
        su["links"][group].forEach(function (el) {
            html += '<a href="' + el.url + '" class="catalogPageTabs__item">' + el.title + '</a>';
        })
    }
    if (su["show_more"])
        html += '<button class="catalogPageTabs__more">Показать больше</button>';
    return html;
}

function updateAttributeCounter(id, data, checkoff) {
    if (typeof data == "undefined" || !data)
        data = [];
    $(".refreshlist[data-attr_id" + id + "]").each(function (i, e) {
        if (id == "!=brand" && (!$(this).attr("data-attr_id") || (!$(this).attr("data-attr_id").match(/^[0-9]+$/) && !$(this).attr("data-attr_id").match(/^sale_filters_/))))
            return true;
        var val = $(this).val();
        if ($(this).attr("data-attr_id") == "instock_status" || $(this).attr("data-attr_id") == "in_expo" || (/^sale_filters_/).test($(this).attr("data-attr_id")))
            val = $(this).attr("data-attr_id");

        if (!data[val]) {
            if (checkoff) {
                $(this).prop("checked", false);
                $(this).attr("disabled", "disabled");
            } else {
                if (!$(this).prop("checked"))
                    $(this).attr("disabled", "disabled");
            }
            $(this).parent().find(".checkboxFilter__textQuantity").text("");
        } else {
            $(this).parent().find(".checkboxFilter__textQuantity").text(data[val]);
            $(this).removeAttr("disabled");
        }
    });
}

function updateCountersForFilters(counters) {
    let checkoff = true;
    if (window.catalog_params.total_items == 0) {
        checkoff = false;
    }
    if (counters.minmax) {
        for (let key in counters.minmax) {
            let $obj = $("input[name='" + key + "']");
            if ($obj.length && !$obj.attr("placeholder")) {
                if (key.indexOf("_max") > 0)
                    $obj.attr("placeholder", "до " + counters.minmax[key]);
                else if (key.indexOf("_min") > 0)
                    $obj.attr("placeholder", "от " + counters.minmax[key]);
            }
        }
    }
    if (counters.minmaxattr) {
        for (let key in counters.minmaxattr) {
            let $obj = $("input[name='f_" + key + "_max']");
            if ($obj.length && !$obj.attr("placeholder")) {
                $obj.attr("placeholder", "до " + counters.minmaxattr[key]["max"]);
            }
            $obj = $("input[name='f_" + key + "_min']");
            if ($obj.length && !$obj.attr("placeholder")) {
                $obj.attr("placeholder", "от " + counters.minmaxattr[key]["min"]);
            }
        }
    }
    if (counters.minmax_ftn) {
        for (let key in counters.minmax_ftn) {
            let $obj = $("input[name='" + key + "_max']");
            if ($obj.length && !$obj.attr("placeholder")) {
                $obj.attr("placeholder", "до " + counters.minmax_ftn[key]["max"]);
            }
            $obj = $("input[name='" + key + "_min']");
            if ($obj.length && !$obj.attr("placeholder")) {
                $obj.attr("placeholder", "от " + counters.minmax_ftn[key]["min"]);
            }
        }
    }
    updateAttributeCounter('=brand', counters.scbrands, checkoff);
    updateAttributeCounter('!=brand', counters.scattr, checkoff);
    updateAttributeCounter('=collects', counters.accollection, checkoff);
    updateAttributeCounter('=ftn_at', counters.count_ftn_at, checkoff);
    //очистка формы поиска бренда
    $(".search_brand").val("").keyup();
}

function updateCollectionsForFilters(collects) {
    if (collects && collects.length) {
        let cur_brand = $("#collection_filter").attr("data-brand");
        if (cur_brand == collects[0]["brand_id"])
            return;
        hideCollectionsForFilters();
        $("#collection_filter").removeClass('is-hidden');
        $("#collection_filter").attr("data-brand", collects[0]["brand_id"]);
        if (collects.length > $("#collection_filter .filters").data("max_show")) {
            $("#collection_filter .filters").addClass("filters--maxElements");
            $("#collection_filter .filters").parent().append('<button class="filters__moreBtn">Показать еще</button>');
        }

        collects.forEach(function (collection) {
            $("#collection_filter .filters").append('<label class="checkboxFilter" for="filter_collects_' + collection["id"] + '">' +
                '<input type="checkbox" class="refreshlist filter" id="filter_collects_' + collection["id"] + '" name="collects[]" data-attr_id="collects" data-urlslug="' + collection["slug"] + '" value="' + collection["id"] + '">' +
                '<span></span>' +
                '<span class="checkboxFilter__text ">' + collection["name"] +
                '<span class="checkboxFilter__textQuantity"></span></span>' +
                '</label>');
        })
    }
}

function hideCollectionsForFilters() {
    $("#collection_filter").addClass('is-hidden');
    $("#collection_filter .filters").removeClass('filters--maxElements');
    $("#collection_filter input").prop("checked", false);
    $("#collection_filter .filters").empty();
    $("#collection_filter .filters__moreBtn").remove();
    $("#collection_filter").removeAttr("data-brand");
}

function updatePageData(first_start) {
    if (!first_start)
        first_start = 0;
    if (window.catalog_params.jqcounters[first_start]) {
        window.catalog_params.jqcounters[first_start].abort();
        window.catalog_params.jqcounters[first_start] = null;
    }
    let data_send = getFiltersByForm(true);

    if (first_start)
        data_send['first_start'] = true;

    //$('.catalog-items-block').addClass('loaded');
    window.catalog_params.jqcounters[first_start] = $.post(window.catalog_params.post.counters, data_send)
        .done(function (data) {
            if (!first_start) {
                if (typeof data["brand_collection_below"] !== "undefined" && data["brand_collection_below"]) {
                    updateCollectionsForFilters(data["brand_collection_below"]);
                } else {
                    hideCollectionsForFilters();
                }
            }
            updateCountersForFilters(data["counters"]);
        })
        .fail(function (data, status) {
            console.log(data);
            if (data.status === 419) {
                //location.reload();
            }
        })
        .always(function () {
            if (first_start) {
                $(".catalogPageFilter.catalogPageFilter__loading").removeClass("catalogPageFilter__loading");
            }
            window.catalog_params.jqcounters[first_start] = null
        });
}

function initBrandsABC(brands) {
    let $main = $("<div/>", {class: "catalogPage__brends catalogPageBrends"});
    $("<div/>", {class: "catalogPageBrends__text", text: "Бренды:"}).appendTo($main);
    let $list = $("<div/>", {class: "catalogPageBrends__list"});

    let brand_lang = null;
    for (let cat in brands) {
        let brand_list = brands[cat];
        if (brand_list) {
            brand_lang_new = "Eng";
            let regexp = /^[a-z\s]+$/i;
            if (!regexp.test(cat))
                brand_lang_new = "Rus";
            if (brand_lang_new != brand_lang) {
                brand_lang = brand_lang_new;
                $("<div/>", {class: "catalogPageBrends__lang", text: brand_lang}).appendTo($list);
            }
            let $main_lang = $("<div/>", {class: "catalogPageBrends__name"});
            $("<div/>", {class: "catalogPageBrends__nameText", text: cat}).appendTo($main_lang);
            let $list_lang = $("<ul/>", {class: "catalogPageBrends__nameList"});
            brand_list.forEach(function (brand_item) {
                $('<li><a href="' + $("#base_url").val() + brand_item["slug"] + '/">' + brand_item["name"] + '</a></li>').appendTo($list_lang);
            })
            $list_lang.appendTo($main_lang);
            $main_lang.appendTo($list);
        }
    }
    ;
    $list.appendTo($main);
    $("#catalog_title_su_brands").append($main);
}

function addProductTolist(el, page) {
    let template = $("#template_card_list").contents().clone();
    template.attr("data-page", page);
    template.attr("data-id", el["id"]);
    template.attr("data-category_id", el["category_id"]);
    template.find(".productCard__imgLink")
        .attr("href", el["url"])
        .find("img")
        .attr("alt", el["name"])
        .attr("data-src", el["thumb"]);
    let tags_block = template.find(".productCard__tegs");
    let country_block = template.find(".productCard__country");
    let bonus_block = template.find(".productCard__bonus");
    let sale_block = template.find(".productCard__sale");
    let promocode_block = template.find(".productCard__promo");
    let reviews_block = template.find(".productCard__reviews");
    let characteristics_block = template.find(".productCard__characteristics");
    let status_block = template.find(".productCard__status");
    let basket_block = template.find(".productCard__basket");
    let delivery_block = template.find(".productCard__basketInfo > .productCard__info");

    if (el["can_buy"] && el["icon_info"]) {
        el["icon_info"].forEach((i) => {
            $('<div class="productCard__teg">' + i['name'] + '</div>').appendTo(tags_block);
        })
    } else {
        tags_block.remove();
    }

    if (el["country"]) {
        country_block.find("img")
            .attr("title", el["country"]["name"])
            .attr("alt", el["country"]["name"])
            .attr("src", country_block.find("img").attr("src") + el["country"]["tag"] + ".png");
        country_block.append(el["country"]["name"]);
    } else {
        country_block.remove();
    }

    template.find(".productCard__price").text(el["price"]["price"]);

    if (el["bonus"]) {
        bonus_block.prepend("+" + el["bonus"]);
        bonus_block.find(".clue__text > span").text("+" + el["bonus"]);
        $(el["bonus_text"]).insertAfter(bonus_block.find(".clue__text > span"));
    } else {
        bonus_block.remove();
    }

    if (el["price"]["sale"]) {
        sale_block.find(".productCard__saleSum").text(el["price"]["old_price"]);
        sale_block.find(".productCard__salePercent").text("-" + el["price"]["sale"] + "%");
    } else {
        sale_block.remove();
    }

    if (el["promocode"]) {
        promocode_block.find("span").eq(0).text(el["promocode"][0]);
        promocode_block.find("span").eq(1).text(el["promocode"][1]);
    } else {
        promocode_block.remove();
    }

    template.find(".productCard__name")
        .attr("href", el["url"])
        .text(el["name"] + el["add_title"]);

    template.find(".rating__filled").css("width", (Math.round(el["rating_avg"] || 0) * 20) + "%");

    if (el["count_feedback"]) {
        reviews_block
            .attr("href", el["url"] + "#feedback")
            .text(el["count_feedback_text"]);
    } else {
        reviews_block.remove();
    }

    //TODO work in characteristic
    characteristics_block.remove();

    if (!el["can_buy"]) {
        status_block.text("Временно отсутствует");
        status_block.find("span").addClass("icon-close2");
    } else if (el["instock_status"] == 2) {
        status_block.removeClass("productCard__status--zakaz").text("В наличии на складе");
        status_block.find("span").addClass("icon-Ok");
    } else if (el["instock_yes"] == 1) {
        status_block.text("Под заказ");
        status_block.find("span").addClass("icon-Delivery");
    } else {
        status_block.remove();
    }

    basket_block
        .attr("data-product", el["id"])
        .attr("data-brand", el["data-brand"])
        .attr("data-category", el["data-category"]);
    if (!el["can_buy"]) {
        basket_block.prop("disabled", true);
    }

    if (el["delivery_text"]) {
        delivery_block.find("span").text(el["delivery_text"]);
    } else {
        delivery_block.remove();
    }

    $(".catalogPageCatalog__productCards").append(template);
}

function addTileTolist(el, page) {
    let template = $("#template_tile_list").contents().clone();
    template.attr("data-page", page);
    template.attr("data-id", el["id"]);
    template.find(".catalogCollectionCard__img")
        .attr("href", el["url"])
        .find("img")
        .attr("alt", el["name"])
        .attr("src", el["thumb"]["pc"])
        .attr("data-srcset", el["thumb"]["pc"] + " 260w, " + el["thumb"]["mob"] + " 420w");
    let tags_block = template.find(".productCard__tegs");
    let country_block = template.find(".productCard__country");
    let preview_products_block = template.find(".preview_products");

    if (el["icon_info"]) {
        el["icon_info"].forEach((i) => {
            $('<div class="productCard__teg">' + i["name"] + '</div>').appendTo(tags_block);
        })
    } else {
        tags_block.remove();
    }

    if (el["country"]) {
        country_block.find("img")
            .attr("title", el["country"]["name"])
            .attr("alt", el["country"]["name"])
            .attr("src", country_block.find("img").attr("src") + el["country"]["tag"] + ".png");
        country_block.append(el["country"]["name"]);
    } else {
        country_block.parent().remove();
    }

    if (el["tail_mins"]["count"]) {
        preview_products_block.attr("data-count", el["tail_mins"]["count"]);
        el["tail_mins"]["photos"].forEach(function (element) {
            $('<a class="catalogCollectionCard__link" href="' + element["url"] + '"><img src="' + template.find(".catalogCollectionCard__img > img").attr("srcset") + '"  width="' + element["width"] + '" data-src="' + element["photo"] + '" class="catalogCollectionCard__imgsCard lazyload"></a>').appendTo(preview_products_block);
        })
    } else {
        preview_products_block.remove();
    }

    template.find(".catalogCollectionCard__price").html(el["price"]["price"]);
    template.find(".catalogCollectionCard__name1").html(el["data-brand"]);
    template.find(".catalogCollectionCard__name2")
        .attr("href", el["url"])
        .text(el["name"]);
    template.find(".designProjectCard__link")
        .attr("href", el["url"]);

    $(".productCards").append(template);
}

function resetSelectedFilter(el) {
    if ($(el).attr("data-type") === "minmax") {
        $(".catalogPageFiltersList input[name='" + $(el).attr("data-filter") + "_min']").val("");
        $(".catalogPageFiltersList input[name='" + $(el).attr("data-filter") + "_max']").val("");
    } else {
        $(el).attr("data-selected").toString().split(',').forEach(function (i) {
            $(".catalogPageFiltersList input[name='" + $(el).attr("data-filter") + "'][value='" + i + "']").prop("checked", false);
        })
    }
    $(el).remove();
}

function updateSelectedFilter(el, type) {
    let added = false;
    if (type === "minmax") {
        let filter = el.name.replace(/_min$/i, "").replace(/_max$/i, "");
        let min_val = $(".catalogPageFiltersList input[name='" + filter + "_min']").val().trim();
        let max_val = $(".catalogPageFiltersList input[name='" + filter + "_max']").val().trim();
        let $filter_object = $(".list_use_filters .reset_one_filter[data-filter='" + filter + "']");
        if (min_val == "" && max_val == "") {
            if ($filter_object.length)
                $filter_object.remove();
        } else {
            let str = "";
            if (max_val == "") {
                str = "от " + min_val;
            } else if (min_val == "") {
                str = "до " + max_val;
            } else {
                str = "от " + min_val + " до " + max_val;
            }

            added = true;
            if ($filter_object.length) {
                $filter_object.find(".catalogPageCatalogTegs__text").text(str);
            } else {
                let filter_name = $(el).closest(".filters").find("button.filters__btn").text().trim();
                $('<button class="catalogPageCatalogTegs__btn btn-reset reset_one_filter" data-type="minmax" data-filter="' + filter + '" data-selected="">' +
                    '<span class="catalogPageCatalogTegs__textC">' + filter_name + ':</span>' +
                    '<span class="catalogPageCatalogTegs__text">' + str + '</span>' +
                    '<span class="catalogPageCatalogTegs__close icon-close2"></span>' +
                    '</button>').prependTo($(".list_use_filters > div"));
            }
        }
    } else {
        let filter = el.name;
        let selected = $(".catalogPageFiltersList input[name='" + filter + "']:checked");
        let $filter_object = $(".list_use_filters .reset_one_filter[data-filter='" + filter + "']");
        if (!selected.length) {
            if ($filter_object.length)
                $filter_object.remove();
        } else {
            added = true;
            let select_str = [];
            let name_str = [];
            selected.each(function () {
                select_str.push(this.value);
                let name = $(this).parent().find(".checkboxFilter__text").clone();
                if (name.find('.checkboxFilter__textQuantity').length)
                    name.find('.checkboxFilter__textQuantity').remove();
                name_str.push(name.text().trim());
            })
            if ($filter_object.length) {
                $filter_object.attr("data-selected", select_str.join(","));
                $filter_object.find(".catalogPageCatalogTegs__text").text(name_str.join(", "));
            } else {
                let filter_name = $(el).closest(".filters").parent().parent().find("button.filters__btn")
                if(filter_name.length != 1)
                    filter_name = null;
                else
                    filter_name = filter_name.text().trim();
                $('<button class="catalogPageCatalogTegs__btn btn-reset reset_one_filter" data-type="in" data-filter="' + filter + '" data-selected="' + select_str.join(",") + '">' +
                    (filter_name?'<span class="catalogPageCatalogTegs__textC">' + filter_name + ':</span>':'') +
                    '<span class="catalogPageCatalogTegs__text">' + name_str.join(", ") + '</span>' +
                    '<span class="catalogPageCatalogTegs__close icon-close2"></span>' +
                    '</button>').prependTo($(".list_use_filters > div"));
            }
        }
    }

    if (added && $(".list_use_filters").hasClass("is-hidden")) {
        $(".list_use_filters").removeClass("is-hidden");
    } else if (!added && !$(".list_use_filters .reset_one_filter").length) {
        $(".list_use_filters").addClass("is-hidden");
    }
}

$(() => {
    if(window.catalog_params)
        updatePageData(1);
    $('.catalogPageCatalog').on('click', '#pagination_block a[data-page]', function (e) {
        e.preventDefault();
        let click_page = parseInt($.trim($(this).attr("data-page")));
        if (click_page && click_page > 0) {
            if (click_page - window.catalog_params.page === 1) {
                window.catalog_params.page = click_page;
                refreshList('more');
            } else {
                window.catalog_params.page = click_page;
                $('html, body').animate({
                    scrollTop: $('.catalogPageCatalog').offset().top - $("header").outerHeight() - 20
                }, 300);
                refreshList(true, getUrlChangePage());
            }

        }
    });

    $('.catalogPageCatalog').on('click', '#catalog_show_more', function () {
        window.catalog_params.page += 1;
        $(this).prop("disabled", true);
        refreshList('more');
    });

    $('.catalogPageCatalog').on('click', '.catalogPageCatalogSort__btn:not(.catalogPageCatalogSort__btn--active)', function (e) {
        e.preventDefault();
        window.catalog_params.page = 1;
        $('.catalogPageCatalogSort__btn.catalogPageCatalogSort__btn--active').removeClass("catalogPageCatalogSort__btn--active");
        $(this).addClass("catalogPageCatalogSort__btn--active");
        let url = getUrlChangeSort();
        refreshList(true, url);
    });

    $('.catalogPageFilter').on('click', '.refreshlist', function (e) {
        if ($(this).attr("data-attr_id") == "brand" && !$(this).prop("checked") && $('.catalogPageFilter input.refreshlist[data-attr_id="collects"]:checked').length) {
            $('.catalogPageFilter input.refreshlist[data-attr_id="collects"]').prop("checked", false);
        }
        updateSelectedFilter(this, "in");
        window.catalog_params.page = 1;
        refreshList(true, undefined, $(this));
    });

    $('.refreshlist_input').on('change', function (e) {
        updateSelectedFilter(this, "minmax");
        refreshList(true);
        //console.log("refreshlist_input focusout");
        //setBackgdound();
    });

    /*$('.refreshlist_input').on('focusin', function (e) {
        console.log("refreshlist_input focusin");
        if (window.catalog_params.keytimeout)
            clearTimeout(window.catalog_params.keytimeout);
    });*/

    //TODO do it for plitka
    /*$('.view_as input[type=radio]').on('change', function () {
        page = 1;
        refreshList(true);
    });*/

    /*
        TODO нужно что бы можно было вернуться назад
        $(".catalog-items-block").on("click",".product a",function(e){
            var count_wr = $(".catalog-items-block .product_wr").length;
            if(count_wr > 1){
                var index_wr = $(".catalog-items-block .product_wr").index($(this).closest(".product_wr"));
                $(".catalog-items-block .product_wr").each(function(i){
                    if(i != index_wr)
                        $(this).hide();
                });
                if(index_wr != count_wr - 1){
                    page = page - (count_wr - 1 - index_wr);
                    var url = getUrlChangePage();
                    window.history.pushState(null, null, url);
                }
            }
            return true;
        });*/

    $(".search_brand").on("keyup", function (e) {
        let search = $.trim($(this).val());
        if (search != $(this).attr('last_search')) {
            $(this).attr('last_search', search);
            let $block_brands = $(this).parent().next();
            $block_brands.find(".is-hidden").removeClass("is-hidden");
            $block_brands.find("center").remove();
            $block_brands.removeClass("filters--searching");
            if (search == "")
                return;
            $block_brands.addClass("filters--searching");
            let $block_letter = null;
            let visible_by_letter = false;
            $block_brands.children().each(function (i) {
                if ($(this).hasClass("filters__letter")) {
                    if ($block_letter !== null && !visible_by_letter) {
                        $block_letter.addClass("is-hidden");
                    }
                    $block_letter = $(this);
                    visible_by_letter = false;
                    return;
                }
                $text_block = $(this).find(".checkboxFilter__text").clone();
                $text_block.find(".checkboxFilter__textQuantity").remove();
                if (!$text_block.text().match(new RegExp(search, 'i'))) {
                    $(this).addClass("is-hidden");
                } else {
                    visible_by_letter = true;
                }
            });
            if ($block_letter && !visible_by_letter)
                $block_letter.addClass("is-hidden");
            if (!$block_brands.find("div:not(.is-hidden)").length) {
                $block_brands.append("<center>По данному запросу ничего не найдено, <a href='#'>очистить запрос</a></center>")
            }
            //console.log($(this).val());
        }
    });
    $(".brands_list").on("click", "center a", function (e) {
        e.preventDefault();
        $(".search_brand").val("").keyup();
    });

    $(".catalogPage").on("click", ".reset_one_filter", function () {
        resetSelectedFilter(this);
        if ($(this).attr("data-filter") === "brand[]" && $(".catalogPage .reset_one_filter[data-filter='collects[]']").length) {
            resetSelectedFilter($(".catalogPage .reset_one_filter[data-filter='collects[]']").get(0));
        }
        if (!$(".list_use_filters .reset_one_filter").length) {
            $(".list_use_filters").addClass("is-hidden");
        }
        refreshList(true);
    })

    $(".catalogPage").on("click", ".reset_all_filters", function () {
        $(this).siblings().each(function () {
            resetSelectedFilter(this);
        })
        $(".list_use_filters").addClass("is-hidden");
        refreshList(true);
    })

    /*
    TODO filters in sale
    $(".category_content").on("click",".collection_category .brands > div",function(){
        if($(this).hasClass('active_cat')) return;
        $(this).closest(".brands").find('.active_cat').removeClass('active_cat');
        $(this).addClass('active_cat');
        if($(this).closest(".filter_block").attr("data-block-id") == 1){
            hidden_filter = $(this).find('span').attr("data-id");
            page = 1;
            refreshList(true);
        }
        else
            showProductsCategory($(this).find('span').attr("data-id"));
    });*/


})
