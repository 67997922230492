// Подключение свайпера
import Swiper from 'swiper/bundle';
import {numWord, priceToNumber, numberToPrice} from './../functions/num-word';
import {isMobile, isTablet, isDesktop} from './../functions/check-viewport';
/*var no_slides = $('.gallery-thumbs .swiper-slide.no-slide').length;
var galleryThumbs = new Swiper('.gallery-thumbs',{
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    direction: 'vertical',
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
        nextEl: '.gallery-thumbs .swiper-button-next',
        prevEl: '.gallery-thumbs .swiper-button-prev',
    },
    on: {
        init: function() {
            $(".gallery-thumbs .swiper-wrapper .swiper-slide").css("opacity", 1);
            $('.gallery-thumbs .swiper-slide').removeClass("swiper-slide-thumb-active");
            $('.gallery-thumbs .swiper-slide').eq(no_slides).addClass("swiper-slide-thumb-active");
        }
    }
});
$('.gallery-thumbs').on("click", ".swiper-slide:not(.no-slide)", function() {
    $('.gallery-thumbs .swiper-slide').removeClass("swiper-slide-thumb-active");
    $(this).addClass("swiper-slide-thumb-active");
    var index = $('.gallery-thumbs .swiper-slide:not(.no-slide)').index(this);
    galleryTop.slideTo(index);
});
galleryTop = new Swiper('.gallery-top',{
    zoom: false,
    slidesPerView: 1,
    preloadImages: false,
    lazy: {
        loadPrevNext: false,
    },
    navigation: {
        nextEl: '.gallery-top .swiper-button-next',
        prevEl: '.gallery-top .swiper-button-prev',
    },
    pagination: {
        el: '.gallery-top .swiper-pagination',
        clickable: true,
    },
    on: {
        init: function() {
            if (this.slides.length > 1)
                this.lazy.loadInSlide(1);
            galleryThumbs.setTranslate(0);
        },
        lazyImageReady: function(slide, img) {
            if ($(slide).find(".dot_image")) {
                update_dot_sliders($(img).attr("src"));
            }
        },
        resize: function() {
            update_dot_sliders();
        },
        slideChange: function() {
            for (var key = 1; key < this.slides.length; key++) {
                this.lazy.loadInSlide(key);
            }
            $('.gallery-thumbs .swiper-slide').removeClass("swiper-slide-thumb-active");
            $('.gallery-thumbs .swiper-slide').eq(this.realIndex + no_slides).addClass("swiper-slide-thumb-active");
            if (!this.realIndex)
                galleryThumbs.slideTo(0);
            else
                galleryThumbs.slideTo(this.realIndex + no_slides);
        }
    },
});*/
if ($(".designProduct").length) {
    let start_init_sliders = false;
    let loaded_data_dops = false;
    let start_init_reviews = false;
    let first_get_reviews = true;

    function resize_product(slider) {
        $(".gallery-thumbs").removeAttr("style");
        $(".gallery-thumbs").height($(".product__galleryImg").height());
        if (slider) {
            slider.updateSize();
            slider.update();
        }
        if ($("#dopProducts").length && !$("#dopProducts .productNecessarily__itemWrap.productNecessarily__itemWrap--active").length) {
            if ($("#dopProducts .productNecessarily__itemWrap .productNecessarilyCard:visible").length != $("#dopProducts .productNecessarily__itemWrap .productNecessarilyCard").length) {
                $("#dopProducts .productNecessarily__btnMore--showMore").show();
            } else {
                $("#dopProducts .productNecessarily__btnMore--showMore").hide();
            }
        }
    }

    function sliderProdMinInit($template, $swiper, product) {
        let $new_slide = $template.clone();
        $new_slide.css("visibility", "visible");
        $new_slide.find(".productCard__imgLink").attr("href", product.url);
        $new_slide.find(".productCard__imgLink img")
            /*.attr("data-src",product.thumb)
            .addClass("swiper-lazy")
            .after('<div class="swiper-lazy-preloader"></div>');*/
            .attr("src", product.thumb)
            .attr("loading", "lazy")/*.after('<div class="swiper-lazy-preloader"></div>')*/;
        $new_slide.find(".productCard__btnLike").removeClass("productCard__btnLike--init");
        if (!product.in_favorite) {
            $new_slide.find(".productCard__btnLike.productCard__btn--active").removeClass("productCard__btn--active");
        }
        $new_slide.find(".productCard__price").text(product.price.price);
        if (product.price.old_price) {
            $new_slide.find(".productCard__saleSum").text(product.price.old_price);
        } else {
            $new_slide.find(".productCard__sale").remove();
        }
        $new_slide.find(".productCard__name")
            .attr("href", product.url)
            .text(product.name + (product.add_title ? " " + product.add_title : ""));
        $new_slide.find(".productCard__item4 button")
            .attr("data-product", product.id);
        $new_slide.appendTo($swiper.find(".swiper-wrapper"));

        $swiper.addClass("sliderProdMin");
    }

    function load_sliders() {
        if (start_init_sliders)
            return;
        start_init_sliders = true;
        let request = {
            "_token": $('meta[name="csrf-token"]').attr('content'),
            "product_id": window.product_params.id
        }
        $.post(window.product_params.ajax.get_data_for_product, request)
            .done(function (data) {
                let $template = $("#similar_products .sliderProdMin__slide--template");
                if (data.similar && data.similar.length) {
                    data.similar.forEach(function (product, index) {
                        sliderProdMinInit($template, $("#similar_products"), product);
                    })
                    $("#similar_products").closest(".productDop").animate({"opacity": 1}, 300);
                }
                if (data.collection_products && data.collection_products.length) {
                    data.collection_products.forEach(function (product, index) {
                        sliderProdMinInit($template, $("#collection_products"), product);
                    })
                    $("#collection_products").closest(".productDop").animate({"opacity": 1}, 300);
                }
                if (data.categories_for_brands && data.categories_for_brands[0]) {
                    let $brand_products = $("#brand_products");
                    let $template_tab = $brand_products.find(".tabsBtn__tab");
                    let $template_tab_block = $brand_products.find(".tabsContent__card");
                    for (let index in data.categories_for_brands) {
                        if (index === "shuffled")
                            continue;
                        let category = data.categories_for_brands[index];
                        let $new_tab = $template_tab.clone();
                        let $new_tab_block = $template_tab_block.clone();
                        let $swiper = $new_tab_block.find(".swiper");

                        $new_tab.removeClass("tabsBtn__tab--active");
                        $new_tab.attr("data-tab", category.id);
                        $new_tab.text(category.name);
                        $new_tab.appendTo($template_tab.parent());

                        $new_tab_block.attr("data-tab-block", category.id);
                        $new_tab_block.removeClass("tabsContent__card--active");
                        $new_tab_block.appendTo($template_tab_block.parent());

                        category.products.forEach(function (product, index) {
                            sliderProdMinInit($template, $swiper, product);
                        });
                    }
                    ;
                    data.categories_for_brands.shuffled.forEach(function (product, index) {
                        sliderProdMinInit($template, $template_tab_block.find(".swiper"), product);
                    })
                    $brand_products.animate({"opacity": 1}, 300);
                }

                window.initTabsBtn();
                window.initBtnLike();
                window.initSliderProdMin();
                $template.remove();
                if (!data.similar || !data.similar.length) {
                    $("#similar_products").closest(".productDop").remove();
                }
                if (!data.collection_products || !data.collection_products.length) {
                    $("#collection_products").closest(".productDop").remove();
                }
                if (!data.categories_for_brands || typeof data.categories_for_brands[0] === "undefined") {
                    $("#brand_products").remove();
                }
            })
            .fail(function (data, status) {
                $("#similar_products").closest(".productDop").remove();
                $("#collection_products").closest(".productDop").remove();
                $("#brand_products").remove();
            })
            .always(function () {

            });
    }

    function fillReviewTemplate($template, el, modal) {
        let class_photos = "reviews__cardPhoto";
        if (modal) {
            class_photos = "modalReviers__cardPhoto";
            if ($template.attr("render_comment_id") == el.id) {
                return;
            }
            $template.attr("render_comment_id", el.id);
        }
        if (el.files.length) {
            let $template_photo = $template.find("." + class_photos).children().first();
            $template.find("." + class_photos + " > img:not(.elem-template)").remove();
            el.files.forEach(function (photo) {
                let $new_photo = $template_photo.clone();
                $new_photo.attr("data-src", photo.thumb);
                /*$new_photo.attr("data-srcbig", photo.image);*/
                $new_photo.attr("data-id", photo.id);
                $new_photo/*.attr("data-comment_id", el.id)*/
                    .removeClass("elem-template")
                    .addClass("showModalReview")
                    .addClass("modal-btn")
                    .attr("data-graph-path", "modalReviers");
                $new_photo.appendTo($template.find("." + class_photos));
            })
            $template.find("." + class_photos).show();
            //$template_photo.remove();
        } else {
            $template.find("." + class_photos).hide();
        }
        $template.find(".reviews__cardName").text(el.fio);
        $template.find(".reviews__cardPosl").text(el.date);

        $template.find(".modalReviers__name").text(el.fio);
        $template.find(".modalReviers__date").text(el.date);

        $template.find(".rating .rating__filled").attr("style", "width:" + (Math.round(el.rating) * 20) + "%");
        let $block_text = $template.find(".reviews__cardText");
        let $block_text_template = $block_text.children().first();
        $block_text.find("> *:not(.elem-template)").remove();
        if (el.plus) {
            let $new_text = $block_text_template.clone();
            $new_text.html("<b>Достоинства: </b> " + el.plus);
            $new_text
                .removeClass("elem-template")
                .appendTo($block_text);
        }
        if (el.minus) {
            let $new_text = $block_text_template.clone();
            $new_text.html("<b>Недостатки: </b> " + el.minus);
            $new_text
                .removeClass("elem-template")
                .appendTo($block_text);
        }
        if (el.text) {
            let $new_text = $block_text_template.clone();
            $new_text.html("<b>Комментарии: </b> " + el.text);
            $new_text
                .removeClass("elem-template")
                .appendTo($block_text);
        }
        //$block_text_template.remove();
    }

    function load_reviews(page, is_mobile) {
        if (typeof is_mobile === "undefined")
            is_mobile = false;
        window.product_params.reviews.loading = true;

        let request = {
            "_token": $('meta[name="csrf-token"]').attr('content'),
            "product_id": window.product_params.id,
            "sort": $(".reviews__sort .catalogPageCatalogSort__btn--active").attr("data-sort"),
            "page": page,
            "first": 0,
            "load_reviews": [],
        }
        if (first_get_reviews) {
            request.first = 1;
            first_get_reviews = false;

            let load_reviews = [];
            if (window.product_params.reviews_slider.init_data.length) {
                window.product_params.reviews_slider.init_data.forEach(function (file) {
                    if (!load_reviews.includes(file["comment_id"]))
                        load_reviews.push(file["comment_id"]);
                })
            }
            request.load_reviews = load_reviews;
        }
        if (typeof window.load_reviews !== "undefined" && window.load_reviews) {
            window.load_reviews.abort();
            window.load_reviews = null;
        }
        if ($("#reviews #reviews_filter_rating input:checked").length) {
            let score = [];
            $("#reviews #reviews_filter_rating input:checked").each(function () {
                score.push($(this).val());
            })
            request.score = score;
        }
        if (is_mobile) {
            $(".allReviewsModal  .reviews__cardMobWrap").append('<div class="loader_spinner"></div>');
        }
        if (page === 1) {
            window.product_params.reviews.page = page;
            $(".allReviewsModal .reviews__cardMobWrap > .reviews__cardMob:not(.reviews__cardMob--template)").remove();
        }
        if (!is_mobile || page === 1) {
            $("#reviews .reviews__cards").addClass("loader_spinner");
            $("#reviews .reviews__cards > .reviews__card:not(.reviews__card--template)").remove();
        }
        if (!is_mobile) {
            if($("#reviews .reviews__sort").length) {
                let scroll_position = $("#reviews .reviews__sort").offset().top - 30 - $(".productHead:visible").outerHeight(true) ?? 0 - $(".headerMob:visible").outerHeight(true) ?? 0;
                if (scroll_position < $(window).scrollTop()) {
                    $(window).scrollTop(scroll_position);
                    /*$("html, body").stop().animate({
                        scrollTop: scroll_position
                    }, 300, "swing");*/
                }
            }
        }

        window.load_reviews = $.post(window.product_params.ajax.load_reviews, request)
            .done(function (data) {
                let all_files = [];
                data.reviews.forEach(function (el, index) {
                    if (request.first && !index) {
                        $("#reviews .reviews__cardMob .reviews__rv").siblings(".loader_spinner").remove();
                        let $reviews__rv = $("#reviews .reviews__cardMob .reviews__rv");
                        fillReviewTemplate($reviews__rv, el, false);
                        $reviews__rv.hide();
                        $reviews__rv.fadeIn(300);
                    }
                    if (page === 1 || is_mobile) {
                        //for mobile
                        let $template = $(".allReviewsModal .reviews__cardMobWrap > .reviews__cardMob.reviews__cardMob--template").clone();
                        $template.removeClass("reviews__cardMob--template");
                        $template.removeAttr("style");
                        fillReviewTemplate($template, el, false);

                        $template.hide();
                        $template.appendTo($(".allReviewsModal .reviews__cardMobWrap"));
                        $template.fadeIn(300);
                    }
                    if (el.files.length) {
                        all_files = all_files.concat(el.files);
                    }
                    if (!is_mobile || page === 1) {
                        let $template = $("#reviews .reviews__cards > .reviews__card.reviews__card--template").clone();
                        $template.removeClass("reviews__card--template");
                        $template.removeAttr("style");
                        if (typeof window.product_params.reviews.list[el.id] == "undefined") {
                            window.product_params.reviews.list[el.id] = el;
                        }
                        fillReviewTemplate($template, el, false);

                        $template.hide();
                        $template.appendTo($("#reviews .reviews__cards"));
                        $template.fadeIn(300);
                    }
                })
                if (all_files.length) {
                    initSlideReviewList(all_files, data.total_pages !== data.page);
                }
                window.initModalBtn();
                if (!is_mobile || page === 1) {
                    $("#pagination_block").html(data.pagination_block);

                    if (data.count_by_rating) {
                        let $block_filter = $("#reviews #reviews_filter_rating");
                        let $block_filter_elem = $block_filter.children().first();
                        for (let rate = 5; rate > 0; rate--) {
                            if (typeof data.count_by_rating[rate] === "undefined")
                                continue;
                            let $new_filter = $block_filter_elem.clone();
                            $new_filter.attr("for", "reviews__filter" + rate);
                            $new_filter.find("input").attr("name", "reviews__filter" + rate);
                            $new_filter.find("input").attr("id", "reviews__filter" + rate);
                            $new_filter.find("input").attr("value", rate);
                            $new_filter.find(".rating__filled").css("width", (rate * 20) + "%");
                            $new_filter.find(".reviews__filterLabelText").append(data.count_by_rating[rate]);
                            $new_filter.appendTo($block_filter);
                            $new_filter.clone().appendTo($(".allReviewsModal .reviews__filterLabelWrap"));
                        }
                        $block_filter_elem.remove();
                        $block_filter.fadeIn(300);
                    }
                    if (data.add_reviews.length) {
                        data.add_reviews.forEach(function (el) {
                            if (typeof window.product_params.reviews.list[el.id] == "undefined") {
                                window.product_params.reviews.list[el.id] = el;
                            }
                        })
                    }
                    if (data.total_pages) {
                        window.product_params.reviews.total_pages = data.total_pages;
                    }
                }
            })
            .fail(function (data, status) {
                if (!is_mobile || page === 1) {
                    $("#reviews .reviews__cards").append("<div class='reviews__card'>Ошибка загрузки, перезагрузите страницу.</div>");
                }
                if (is_mobile && page > 1) {
                    window.product_params.reviews.page--;
                }
            })
            .always(function () {
                window.load_reviews = null;
                window.product_params.reviews.loading = false;
                $("#reviews .reviews__cards").removeClass("loader_spinner");
                $(".allReviewsModal  .reviews__cardMobWrap .loader_spinner").remove();
                if (is_mobile) {
                    $(".allReviewsModal .allReviewsModal__contentWrap").trigger("scroll");
                }
            });
    }

    function scroll_product() {
        let top = $(window).scrollTop();
        if ($("#similar_products").length) {
            let top_block = $("#similar_products").offset().top;
            let w_h = $(window).height();
            if (top > top_block - w_h - 300) {
                load_sliders();
                if (!start_init_reviews && window.product_params.count_feedback) {
                    start_init_reviews = true;
                    load_reviews(1);
                }
            }
        }
    }

    function resize_reviews() {
        if ($(".product__galleryReviews").length && $(".product__galleryReviews .product__galleryReviewsImgWrap").length) {
            $(".product__galleryReviews .product__galleryReviewsImgWrap[data-col]").removeAttr("data-col");
            let visible = $(".product__galleryReviews .product__galleryReviewsImgWrap:visible").length;
            let all_count = +$(".product__galleryReviews").data("count");
            if (visible < all_count) {
                $(".product__galleryReviews .product__galleryReviewsImgWrap:visible").last().attr("data-col", "+" + (all_count - visible + 1));
            }
        }
    }

    function initSlideReviewList(slides, more) {
        if (slides && slides.length) {
            let slide_prev = null;
            let slides_index_max = slides.length - 1;
            slides.forEach(function (file, index) {
                if (!window.product_params.reviews_slider.list) {
                    slide_prev = -1;
                    window.product_params.reviews_slider.list = {};
                }

                let slide_next = null;
                if (index !== slides_index_max) {
                    slide_next = slides[index + 1]["id"];
                } else if (!more) {
                    slide_next = -1;
                }
                file["prev"] = slide_prev;
                file["next"] = slide_next;
                if (typeof window.product_params.reviews_slider.list[file.id] === "undefined") {
                    window.product_params.reviews_slider.list[file.id] = file;
                } else {
                    if (slide_prev && !window.product_params.reviews_slider.list[file.id]["prev"])
                        window.product_params.reviews_slider.list[file.id]["prev"] = slide_prev;
                    if (slide_next && !window.product_params.reviews_slider.list[file.id]["next"])
                        window.product_params.reviews_slider.list[file.id]["next"] = slide_next;
                }

                slide_prev = file.id;
            });
        }
    }

    function insertSlideReview(slides) {
        if (slides && slides.length) {
            slides.forEach(function (file, index) {
                let $new_slide = $('<div class="swiper-slide reviewsSlider__slide"><img loading="lazy" ></div>');
                $new_slide.find("img")
                    .attr("src", file.thumb)
                    .addClass("showModalReview")
                    .addClass("modal-btn")
                    .attr("data-graph-path", "modalReviers")
                    .attr("data-id", file.id)
                /*.attr("data-comment_id", file.comment_id)
                .attr("data-srcbig", file.image)*/;

                $new_slide.appendTo($(".reviewsSlider .swiper-wrapper"));
            });
            window.initModalBtn();
            $(".reviewsSlider").each(function () {
                this.swiper.update();
            })
        }
    }

    function loadSlidesReview() {
        if (window.product_params.reviews_slider.more && !window.product_params.reviews_slider.loading) {
            window.product_params.reviews_slider.loading = true;
            window.product_params.reviews_slider.page += 1;
            let request = {
                "_token": $('meta[name="csrf-token"]').attr('content'),
                "product_id": window.product_params.id,
                "page": window.product_params.reviews_slider.page,
            }
            $.post(window.product_params.ajax.load_review_images, request)
                .done(function (data) {
                    if (!data.feedback_files_more)
                        window.product_params.reviews_slider.more = false;
                    initSlideReviewList(data.feedback_files, window.product_params.reviews_slider.more);
                    insertSlideReview(data.feedback_files);
                    checkLoadSlidesReview();
                })
                .fail(function (data, status) {
                    window.product_params.reviews_slider.page -= 1;
                    window.product_params.reviews_slider.step++;
                    if (window.product_params.reviews_slider.step < 3) {
                        setTimeout(checkLoadSlidesReview, 1000);
                    }
                })
                .always(function () {
                    window.product_params.reviews_slider.loading = false;
                });
        }
    }

    function checkLoadSlidesReview(swipers) {
        if (typeof swiper == "undefined") {
            swipers = [];
            $(".reviewsSlider").each(function () {
                swipers.push(this.swiper);
            })
        } else {
            swipers = [swipers];
        }
        if (swipers && swipers.length) {
            swipers.forEach(function (swiper) {
                if (typeof swiper !== "undefined" && swiper.getTranslate() < swiper.width - swiper.virtualSize + 240) {
                    loadSlidesReview();
                }
            })
        }
    }


    function insertSlidersModalReview(file, dir, update) {
        let $tmp_slide = $('<div class="swiper-slide modalReviersSwiper__slide"><img class="modalReviersSwiper__img"></div>');
        /*if(not_lazy !== true) {
            $tmp_slide.find("img").attr("loading","lazy");
        }*/
        $tmp_slide.css('background-image', 'url(' + file["image"] + ')')
            .attr("data-id", file["id"]);
        $tmp_slide.find("img").attr("src", file["image"]);

        let callback_images = new Image();
        callback_images.src = file["image"];
        callback_images.onload = function () {
            if (isMobile() && $(".modalReviers").is(":visible")) {
                window.modalReviersSwiper.updateAutoHeight(0);
            }
        }

        if (dir) {
            $tmp_slide.appendTo($(".modalReviersSwiper .swiper-wrapper"));
        } else {
            $tmp_slide.prependTo($(".modalReviersSwiper .swiper-wrapper"));
        }
        if (update) {
            window.modalReviersSwiper.update();
        }
    }

    function getReviewImagesByDate(review_image, dir) {
        let expect = [];
        let main_review_image = review_image;
        while (review_image[dir] != null && review_image[dir] !== -1) {
            review_image = window.product_params.reviews_slider.list[review_image[dir]];
            if (review_image["date"] === main_review_image["date"]) {
                expect.push(review_image["id"]);
            } else
                break;
        }
        return expect;
    }

    function renderSlidersModalReview(file_id, dir) {
        let current_slider_reviews = window.product_params.reviews_slider.current_slider_reviews;
        let cur_file = window.product_params.reviews_slider.list[file_id];
        let comment_id = cur_file.comment_id;
        if (!$(".modalReviersSwiper .swiper-slide[data-id=" + file_id + "]").length)
            insertSlidersModalReview(cur_file, 1, false);
        let cur_file_prev = cur_file,
            cur_file_next = cur_file;

        //init_prev
        let count_need_max = 5;
        let count_sliders_prev = count_need_max;
        if (dir === "all" || dir === "prev") {
            count_sliders_prev = 0;
            while (count_sliders_prev < count_need_max) {
                if (cur_file_prev["prev"]) {
                    if (cur_file_prev["prev"] === -1) {
                        count_sliders_prev = count_need_max;
                        break;
                    } else {
                        count_sliders_prev++;
                        cur_file_prev = window.product_params.reviews_slider.list[cur_file_prev["prev"]];
                        insertSlidersModalReview(cur_file_prev, 0, false);
                    }
                } else {
                    break;
                }
            }
        }

        //init_next
        let count_sliders_next = count_need_max;
        if (dir === "all" || dir === "next") {
            count_sliders_next = 0;
            while (count_sliders_next < count_need_max) {
                if (cur_file_next["next"]) {
                    if (cur_file_next["next"] === -1) {
                        count_sliders_next = count_need_max;
                        break;
                    } else {
                        count_sliders_next++;
                        cur_file_next = window.product_params.reviews_slider.list[cur_file_next["next"]];
                        insertSlidersModalReview(cur_file_next, 1, false);
                    }
                } else {
                    break;
                }
            }
        }

        let activeIndex = $(".modalReviersSwiper .swiper-slide").index($(".modalReviersSwiper .swiper-slide[data-id=" + file_id + "]"))
        if ($(".modalReviersSwiper .swiper-slide.swiper-slide-active").length) {
            activeIndex = $(".modalReviersSwiper .swiper-slide").index($(".modalReviersSwiper .swiper-slide.swiper-slide-active"))
        }
        window.modalReviersSwiper.activeIndex = activeIndex;
        window.modalReviersSwiper.update();

        if (count_sliders_prev !== count_need_max || count_sliders_next !== count_need_max) {
            window.product_params.reviews_slider.loading_images = true;

            let request = {
                "_token": $('meta[name="csrf-token"]').attr('content'),
                "product_id": window.product_params.id,
                "prev": count_need_max - count_sliders_prev,
                "last_prev": cur_file_prev.comment_id,
                "prev_except": (count_need_max - count_sliders_prev ? getReviewImagesByDate(cur_file_prev, "next") : []),
                "next": count_need_max - count_sliders_next,
                "last_next": cur_file_next.comment_id,
                "next_except": (count_need_max - count_sliders_next ? getReviewImagesByDate(cur_file_next, "prev") : []),
            }
            $.post(window.product_params.ajax.load_modal_review_images, request)
                .done(function (data) {
                    if (data.prev_files)
                        initSlideReviewList(data.prev_files, true);
                    if (data.next_files)
                        initSlideReviewList(data.next_files, data.next_more);
                    if (data.comments) {
                        data.comments.forEach(function (el) {
                            if (typeof window.product_params.reviews.list[el.id] == "undefined") {
                                window.product_params.reviews.list[el.id] = el;
                            }
                        });
                    }
                    if (current_slider_reviews == window.product_params.reviews_slider.current_slider_reviews)
                        renderSlidersModalReview(file_id, dir);
                })
                .fail(function (data, status) {

                })
                .always(function () {
                    window.product_params.reviews_slider.loading_images = false;
                });
        }
    }

    function renderDopItemActive(product_id) {
        let product = window.product_params.additions[product_id];
        let $block = $(".dopProdModal__prod-common");
        $block.scrollTop(0);
        $block.find(".dopProdModal__prodImg img").attr("src", product.image_dop);
        $block.attr("data-id", product.id);
        if (!product.in_favorite)
            $block.find(".productCard__btnLike").removeClass("productCard__btn--active")
        if (!product.in_sravni)
            $block.find(".productCard__btnFav").removeClass("productCard__btn--active")
        $block.find(".dopProdModal__cardName")
            .text($.trim(product.name + " " + product.add_title));
        $block.find(".product__btnsPriceBlock .product__btnsPrice").text(product.price.price);
        if (product.price.old_price) {
            $block.find(".product__btnsPriceBlock .product__btnsPrice").append("<span>" + product.price.old_price + "</span>");
            $block.find(".product__btnsPriceBlock .product__btnsSale").text("-" + product.price.sale).show();
        } else {
            $block.find(".product__btnsPriceBlock .product__btnsSale").hide();
        }

        if (product.bonus) {
            $block.find(".dopProdModalInfo__Bonus").html("<b>+" + product.bonus + "</b><br>" + product.bonus_text);
            $block.find(".dopProdModalInfo__Bonus").closest(".clue").show()
        } else {
            $block.find(".dopProdModalInfo__Bonus").closest(".clue").hide()
        }

        let status_block = $block.find(".productCard__status");
        status_block.show();

        //TODO вывести в компанент, так как повторяется с каталогом
        if (!product["can_buy"]) {
            status_block.addClass("productCard__status--zakaz").text("Временно отсутствует");
            status_block.find("span")
                .addClass("icon-close2")
                .removeClass("icon-Ok")
                .removeClass("icon-Delivery");
        } else if (product["instock_status"] == 2) {
            status_block.removeClass("productCard__status--zakaz").text("В наличии на складе");
            status_block.find("span")
                .addClass("icon-Ok")
                .removeClass("icon-Delivery")
                .removeClass("icon-close2");
        } else if (product["instock_yes"] == 1) {
            status_block.addClass("productCard__status--zakaz").text("Под заказ");
            status_block.find("span")
                .addClass("icon-Delivery")
                .removeClass("icon-Ok")
                .removeClass("icon-close2");
        } else {
            status_block.hide();
        }

        $block.find(".dopProdModalInfo__Delivery").hide();
        if (product.delivery_text_full) {
            $block.find(".dopProdModalInfo__Delivery").show();
            $block.find(".dopProdModalInfo__Delivery .product__btnsInfStr:nth-child(1) b").html(product.delivery_text_full);
            $block.find(".dopProdModalInfo__Delivery .product__btnsInfStr:nth-child(3) b").html(product.delivery_text_cost);
        }

        $block.find(".dopProdModalInfo__Country").hide();
        let country_block = $block.find(".dopProdModalInfo__Country .product__infoBlockRight");
        if (product["country"]) {
            $block.find(".dopProdModalInfo__Country").show();
            country_block.html(country_block.find("img").get(0));
            country_block.find("img")
                .attr("title", product["country"]["name"])
                .attr("alt", product["country"]["name"])
                .attr("src", country_block.find("img").attr("data-src") + product["country"]["tag"] + ".png");
            country_block.append(" " + product["country"]["name"]);
        }

        $block.find(".dopProdModalInfo__Brand .product__infoBlockRight > a")
            .text(product["data-brand"])
            .attr("href", "/" + product["data-brand-slug"] + "/");

        $block.find(".dopProdModalInfo__Collection").hide();
        $block.find(".dopProdModalLink__Collection").hide();
        if (product["collection"]) {
            $block.find(".dopProdModalInfo__Collection").show();
            if (product["collection"]["slug"]) {
                $block.find(".dopProdModalInfo__Collection .product__infoBlockRight").html("<a href='" + product["collection"]["slug"] + "'>" + product["collection"]["name"] + "</a>");
                $block.find(".dopProdModalLink__Collection")
                    .attr("href", product.collection.slug)
                    .text("Все товары коллекции " + product.collection.name)
                    .show();
            }
            else {
                $block.find(".dopProdModalInfo__Collection .product__infoBlockRight").html(product["collection"]["name"]);
            }
        }

        $block.find(".dopProdModalInfo__Article").hide();
        if (product["articles"]) {
            $block.find(".dopProdModalInfo__Article").show();
            $block.find(".dopProdModalInfo__Article .product__infoBlockRight")
                .text(product["articles"]["article_add"]);
        }

        $block.find(".dopProdModalInfo__Discounted").hide();
        if (product["add_title"]) {
            $block.find(".dopProdModalInfo__Discounted").show();
        }

        $block.find(".product__infoBlock > a").attr("href", product.url);
        $block.find(".product__dillerImg").attr("src", product["data-brand-logo"])
        $block.find(".dopProdModalLink__Brands")
            .attr("href", product["data-category-slug"] + product["data-brand-slug"] + "/")
            .text("Все " + product["data-category-name"].toLowerCase() + " " + product["data-brand"]);

    }

    function check_complect() {
        console.log("check komplect");
        let count_complect = $("#dopProducts .productNecessarilyCard--compl").length + $("#dopProducts .productNecessarilyCard:not(.productNecessarilyCard--compl) .productNecessarilyInput:checked").length;
        $(".count_complect").text(count_complect + " " + numWord(count_complect, ["товар", "товара", "товаров"]));
        if(count_complect > 1)
            $(".is_select_dop").show();
        else
            $(".is_select_dop").hide();

        let price = +$(".product__info .product__btnsPriceBlock meta").attr("content");
        let old_price = price;
        if($(".product .product__info .product__btnsPriceBlock .product__btnsPrice > span").length) {
            old_price = priceToNumber($(".product .product__info .product__btnsPriceBlock .product__btnsPrice > span").text());
        }
        $("#dopProducts .productNecessarilyCard:not(.productNecessarilyCard--compl) .productNecessarilyInput:checked").each(function() {
            let product_id = $(this).val();
            if (window.product_params.additions[product_id]) {
                let product = window.product_params.additions[product_id];
                let price_item = priceToNumber(product.price.price);
                price += price_item;
                if(product.price.old_price) {
                    old_price += priceToNumber(product.price.old_price);
                }
                else {
                    old_price += price_item;
                }
            }
        })
        $(".price_complect").text(numberToPrice(price));
        $(".price_complect_single").text(numberToPrice(price));
        $(".price_complect_sale").text(numberToPrice(old_price - price));
        if(old_price > price) {
            $(".price_complect").append("<span>" + numberToPrice(old_price) + "</span>");
            $(".price_complect_sale").closest(".productBottom__line").show();
        }
        else {
            $(".price_complect_sale").closest(".productBottom__line").hide();
        }
    }

    function check_richText() {
        let $block = $(".productInform .richText");
        $block.removeClass("richText__more");
        $block.find(".richText__showMore").remove();
        if(!$block.hasClass("richText__more--active")) {
            if ($block.height() > 200) {
                $block.addClass("richText__more");
                $block.append('<button class="product__anchor richText__showMore btn-reset" style="">Показать полностью<i class="icon-expand_more productNecessarily__btnMoreICon"></i></button>')
            }
        }
    }

    $(function () {
        window.reviewsSlider = new Swiper(".reviewsSlider", {
            loop: false,
            slidesPerView: "auto",
            speed: 1000,
            autoplay: "false",
            breakpoints: {
                0: {
                    spaceBetween: 10,
                },
                1026: {
                    spaceBetween: 15,
                }
            },
            navigation: {
                nextEl: ".reviewsSlider ~ .swiper-button-next-custom",
                prevEl: ".reviewsSlider ~ .swiper-button-prev-custom",
            },
            on: {
                transitionEnd: function (swiper) {
                    checkLoadSlidesReview(swiper);
                },
                sliderMove: function (swiper) {
                    checkLoadSlidesReview(swiper);
                }
            }
        });
        if (window.product_params.reviews_slider.init_data) {
            insertSlideReview(window.product_params.reviews_slider.init_data);
            initSlideReviewList(window.product_params.reviews_slider.init_data, window.product_params.reviews_slider.more);
        }
        //init review modal for mobile
        if ($("#reviews #reviews_filter_rating").length) {
            let $modal = $(".allReviewsModal");
            $modal.find(".reviews__filterRaitingText").text($("#reviews .reviews__filterRaitingText").first().text());
            $modal.find(".reviews__filterRaiting .rating__filled").attr("style", $("#reviews .reviews__filterRaiting .rating__filled").first().attr("style"));
            $modal.find(".reviews__filterRaiting .reviews__filterRaitingText1").text($("#reviews .reviews__filterRaitingText1").first().text());

        }


        window.modalReviersSwiper = new Swiper(".modalReviersSwiper", {
            loop: false,
            slidesPerView: 1,
            speed: 1000,
            breakpoints: {
                0: {
                    autoHeight: true,
                },
                770: {
                    autoHeight: false,
                }
            },
            autoplay: "false",
            longSwipes: false,
            navigation: {
                nextEl: ".modalReviersSwiper ~ .swiper-button-next-custom",
                prevEl: ".modalReviersSwiper ~ .swiper-button-prev-custom",
            },
            on: {
                slideChange: function (swiper) {
                    let cur_image = window.product_params.reviews_slider.list[swiper.slides[swiper.activeIndex].dataset.id];
                    let cur_review = window.product_params.reviews.list[cur_image["comment_id"]];
                    fillReviewTemplate($(".modalReviers"), cur_review, true);

                    let count_sliders = swiper.slides.length;
                    if (swiper.activeIndex > swiper.previousIndex) {
                        if (count_sliders - swiper.activeIndex < 5) {
                            let image_id = swiper.slides[count_sliders - 1].dataset.id;
                            let file = window.product_params.reviews_slider.list[image_id];
                            if (file["next"] !== -1) {
                                renderSlidersModalReview(image_id, "next");
                            }
                        }
                    } else if (swiper.activeIndex < swiper.previousIndex) {
                        if (swiper.activeIndex < 5) {
                            let image_id = swiper.slides[0].dataset.id;
                            let file = window.product_params.reviews_slider.list[image_id];
                            if (file["prev"] !== -1) {
                                renderSlidersModalReview(image_id, "prev");
                            }
                        }
                    }
                },
                /*resize: function(swiper) {
                    swiper.updateAutoHeight(0);
                }*/
            }
        });

        $(".copy_cod_product").on("click", function (e) {
            e.preventDefault();
            if (navigator.clipboard) {
                navigator.clipboard.writeText($(this).prev().text()).then(
                    function () {
                    },
                    function (err) {
                    }
                );
            }
        })

        var no_slides = $('.gallery-thumbs .swiper-slide.no-slide').length;
        resize_product();
        window.galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 0,
            slidesPerView: 'auto',
            freeMode: true,
            direction: 'vertical',
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.gallery-thumbs .swiper-button-next-vertical',
                prevEl: '.gallery-thumbs .swiper-button-prev-vertical',
            },
            on: {
                init: function () {
                    $(".gallery-thumbs .product__galleryVert").css("gap", 0);
                    $(".gallery-thumbs .swiper-wrapper .swiper-slide").css("opacity", 1);
                    $('.gallery-thumbs .swiper-slide').removeClass("product__galleryImgMin--active");
                    $('.gallery-thumbs .swiper-slide').eq(no_slides).addClass("product__galleryImgMin--active");
                }
            }
        });


        $('.gallery-thumbs').on("click", ".swiper-slide:not(.no-slide)", function () {
            $('.gallery-thumbs .swiper-slide').removeClass("product__galleryImgMin--active");
            $(this).addClass("product__galleryImgMin--active");
            var index = $('.gallery-thumbs .swiper-slide:not(.no-slide)').index(this);
            galleryTop.slideTo(index);
        });

        const galleryTop = new Swiper('.product__galleryTop', {
            zoom: false,
            slidesPerView: 1,
            preloadImages: false,
            lazy: {
                loadPrevNext: false,
            },
            /*navigation: {
                nextEl: '.gallery-top .swiper-button-next',
                prevEl: '.gallery-top .swiper-button-prev',
            },*/
            /*pagination: {
                el: '.gallery-top .swiper-pagination',
                clickable: true,
            },*/
            on: {
                init: function () {
                    if (this.slides.length > 1)
                        this.lazy.loadInSlide(1);
                    galleryThumbs.setTranslate(0);
                    let $block_pag = $(".product__galleryMain .productCard__imgTabs");
                    let $block_pag_items = $block_pag.children().eq(0);
                    for (let i = 1; i < $('.gallery-thumbs .swiper-slide:not(.no-slide)').length; i++) {
                        $block_pag_items.clone().removeClass("productCard__imgTab--active").appendTo($block_pag);
                    }

                },
                /*lazyImageReady: function(slide, img) {
                    if ($(slide).find(".dot_image")) {
                        update_dot_sliders($(img).attr("src"));
                    }
                },
                resize: function() {
                    update_dot_sliders();
                },*/
                slideChange: function () {
                    for (var key = 1; key < this.slides.length; key++) {
                        this.lazy.loadInSlide(key);
                    }
                    $('.gallery-thumbs .swiper-slide').removeClass("product__galleryImgMin--active");
                    $('.gallery-thumbs .swiper-slide').eq(this.realIndex + no_slides).addClass("product__galleryImgMin--active");
                    $(".product__galleryMain .productCard__imgTabs .productCard__imgTab.productCard__imgTab--active").removeClass("productCard__imgTab--active");
                    $(".product__galleryMain .productCard__imgTabs .productCard__imgTab").eq(this.realIndex).addClass("productCard__imgTab--active");
                    if (!this.realIndex)
                        galleryThumbs.slideTo(0);
                    else
                        galleryThumbs.slideTo(this.realIndex + no_slides);
                }
            },
        });

        $("#reviews #pagination_block").on("click", ".pagination__btn:not(.pagination__btn--active)", function (e) {
            e.preventDefault();
            if ($(this).attr("data-page")) {
                load_reviews(+$(this).attr("data-page"));
            }
        });

        $("#reviews").on("click", ".catalogPageCatalogSort__btn:not(.catalogPageCatalogSort__btn--active)", function (e) {
            e.preventDefault();
            $(this).siblings().removeClass("catalogPageCatalogSort__btn--active")
            $(this).addClass("catalogPageCatalogSort__btn--active");
            load_reviews(1);
        })

        $("#reviews").on("change", "#reviews_filter_rating input", function (e) {
            $(".allReviewsModal .reviews__filterLabelWrap input[name=" + this.name + "]").prop("checked", $(this).prop("checked"));

            load_reviews(1, $(".allReviewsModal").is(":visible"));
        })

        $(document).on("click", ".showModalReview", function () {
            $(".modalReviersSwiper .swiper-wrapper").empty();
            let file_id = $(this).attr("data-id");
            let cur_file = window.product_params.reviews_slider.list[file_id];
            let comment_id = cur_file.comment_id;
            window.product_params.reviews_slider.current_slider_reviews = file_id + "_" + comment_id;
            renderSlidersModalReview(file_id, "all");
            fillReviewTemplate($(".modalReviers"), window.product_params.reviews.list[comment_id], true);
        })

        $(window).on("resize", function () {
            resize_product(galleryThumbs);
            check_richText();
            scroll_product();
            resize_reviews();
        });
        $(window).on("scroll", function () {
            scroll_product();
        });

        $(".allReviewsModal .allReviewsModal__contentWrap").on("scroll", function () {
            let $last_review_mob = $(".allReviewsModal .allReviewsModal__contentWrap .reviews__cardMobWrap > .reviews__cardMob:last-child")
            if ($last_review_mob.length && !window.product_params.reviews.loading) {
                let all_height_scroll = $last_review_mob.offset().top + $last_review_mob.height() - $(this).height();
                if (window.product_params.reviews.total_pages > window.product_params.reviews.page && all_height_scroll - $(this).scrollTop() < 400) {
                    load_reviews(++window.product_params.reviews.page, true);
                }
            }
        });

        $("#dopProducts .btnStnd--choice").on("click", function (e) {
            if (!loaded_data_dops) {
                loaded_data_dops = true;
                let additions = Object.keys(window.product_params.additions);
                if (additions.length) {
                    let request = {
                        "_token": $('meta[name="csrf-token"]').attr('content'),
                        "ids": additions,
                    }
                    $.post(window.product_params.ajax.load_data_dops, request)
                        .done(function (data) {
                            if (data.articles) {
                                for (let key in data.articles) {
                                    if (window.product_params.additions[key] && data.articles[key])
                                        window.product_params.additions[key]["articles"] = {
                                            "article_add": data.articles[key]
                                        };
                                }
                            }
                            if (data.collections) {
                                for (let key in data.collections) {
                                    if (window.product_params.additions[key])
                                        window.product_params.additions[key]["collection"] = data.collections[key];
                                }
                            }
                            renderDopItemActive($(".dopProdModal__prod-common").attr("data-id"));
                        });
                }
            }
            let $modal_dops = $(".dopProdModal");
            let $template_dop_item = $modal_dops.find(".dopProdModal__card.elem-template");
            let title_block = $(this).closest(".productNecessarilyCard").find(".productNecessarilyCard__name").data("title");
            let ids = $(this).data("ids").split(",");

            $modal_dops.find(".dopProdModal__title").text(title_block);
            $modal_dops.find(".dopProdModal__card:not(.elem-template)").remove();
            ids.forEach(function (el) {
                if (window.product_params.additions[el]) {
                    let product = window.product_params.additions[el];
                    let $item = $template_dop_item.clone();
                    $item.removeClass("elem-template");
                    $item.attr("data-id", product.id);
                    $item.find(".dopProdModal__cardImg img").attr("data-src", product.thumb);
                    if (!product.in_favorite)
                        $item.find(".productCard__btnLike").removeClass("productCard__btn--active")
                    if (!product.in_sravni)
                        $item.find(".productCard__btnFav").removeClass("productCard__btn--active")

                    $item.find(".dopProdModal__cardInfo .dopProdModal__cardName")
                        .text($.trim(product.name + " " + product.add_title))
                        .attr("href", product.url);
                    $item.find(".dopProdModal__cardInfo .dopProdModal__cardPrice").text(product.price.price);
                    if (product.price.old_price)
                        $item.find(".dopProdModal__cardInfo .dopProdModal__cardPrice").append("<span>" + product.price.old_price + "</span>");
                    $item.find(".dopProdModal__openAbout").removeClass("modal-btn-inited");
                    $item.appendTo($template_dop_item.parent());
                }
            })
            $modal_dops.find(".dopProdModal__card:not(.elem-template)").first().find(".dopProdModal__cardBtn2").trigger("click");
            window.initModalBtn();

        });

        $(".dopProdModal").on("click", ".dopProdModal__openAbout", function (e) {
            $(this).closest(".dopProdModal__card").siblings().removeClass("dopProdModal__card--active");
            $(this).closest(".dopProdModal__card").addClass("dopProdModal__card--active");
            let product_id = $(this).closest(".dopProdModal__card").data("id");
            renderDopItemActive(product_id);
        })

        $(".dopProdModal, .prodPodrModal").on("click", ".add_to_complect", function (e) {
            let product_id = $(this).closest(".product-elem").attr("data-id");
            if (window.product_params.additions[product_id]) {
                let product = window.product_params.additions[product_id];
                let $card = $(".productNecessarilyCard[data-id=" + product.product_type_id + "]");
                if($card.length) {
                    $card.find(".productNecessarilyCard__label").show();
                    $card.find(".productNecessarilyCard__label input").val(product.id).prop("checked", true);
                    $card.find(".productNecessarilyCard__imgWrap img").attr("src", product.thumb);
                    $card.find(".productNecessarilyCard__name")
                        .attr("href", product.url)
                        .text(product.name);
                    $card.find(".productNecessarilyCard__price").text(product.price.price);
                    if (product.price.old_price)
                        $card.find(".productNecessarilyCard__price").append("<span>" + product.price.old_price + "</span>");
                    $card.find(".modal-btn").text("Выбрать другой");
                    closeModal();
                    check_complect();
                }
            }
        })

        $("#dopProducts").on("change", ".productNecessarilyInput", function() {
            check_complect();
        })

        $(".richText").on("click", ".richText__showMore", function () {
            $(this).closest(".richText")
                .removeClass("richText__more")
                .addClass("richText__more--active");
            $(this).remove();
        })

        scroll_product();
        resize_reviews();
        check_richText();
    });
}
